import { PageTypeEnum, ReportType } from "utils/reportDefinitions";
import { SupportedLanguageEnum, TerminalRequestDeliveryType } from "utils/enums";
import { StringResources } from "./languageResource";

export const translationsEn = {
    [StringResources.modal.close]: "Close",
    [StringResources.modal.confirm]: "Confirm",
    [StringResources.modal.delete]: "Delete",
    [StringResources.modal.cancel]: "Cancel",
    [StringResources.modal.set]: "Set",
    [StringResources.modal.save]: "Save",
    [StringResources.modal.details]: "Details",
    [StringResources.modal.submit]: "Submit",
    [StringResources.modal.create]: "Create",
    [StringResources.modal.feedback.title]: "Feedback",
    [StringResources.modal.feedback.label]: "Message",
    [StringResources.modal.feedback.placeholder]: "Type your reply here",
    [StringResources.modal.feedback.success]: "Feedback message has been sent successfully",
    [StringResources.modal.feedback.error]: "Feedback message didn't sent",
    [StringResources.modal.feedback.message]:
        "Straumur support will see this Feedback message and we will reach out to you.",
    [StringResources.modal.column]: "Column",
    [StringResources.modal.filter]: "Filter",
    [StringResources.modal.deleteModal.title]: "Confirm Delete",
    [StringResources.modal.deleteModal.message]: "Are you sure you want to delete this item?",
    [StringResources.modal.refundTransaction.title]: "Refund transaction?",
    [StringResources.modal.refundTransaction.message]:
        "The transaction will be refunded to the customer. This action cannot be undone.",
    [StringResources.modal.refundTransaction.refund]: "Refund",
    [StringResources.modal.refundTransaction.fullRefund]: "Full refund",
    [StringResources.modal.refundTransaction.partialRefund]: "Partial refund",
    [StringResources.modal.refundTransaction.formRefundAmount]: "Refund amount",
    [StringResources.modal.refundTransaction.formRefundAmountRequired]: "Field is required",
    [StringResources.modal.refundTransaction.formRefundAmountMin]: "Must be greater than or equal {{min}}",
    [StringResources.modal.refundTransaction.formRefundAmountMax]: "Must be less or equal {{max}}",
    [StringResources.modal.refundTransaction.maxAmountForRefund]: "Max amount for refund: {{maxAmount}}",
    [StringResources.modal.refundTransaction.amountForRefund]: "Amount for refund: {{amount}}",
    [StringResources.modal.refundTransaction.success]: "Transaction has been refunded successfully",
    [StringResources.modal.refundTransaction.error]: "Error occurred while refunding transaction",
    [StringResources.modal.refundTransaction.downloadSuccess]: "Successfully downloaded refund receipt",
    [StringResources.modal.refundTransaction.downloadError]: "Error while downloading refund receipt",
    [StringResources.modal.refundTransaction.confirmRefund]: "Confirm refund",
    [StringResources.modal.refundTransaction.confirmRefundMessage]:
        "Are you sure you want to refund this transaction? You will refund {{amount}} of the {{maxAmount}} to the customer.",
    [StringResources.modal.refundTransaction.downloadRefundReceipt]: "Download refund receipt",

    [StringResources.modal.createPaymentLink.title]: "Create payment link",
    [StringResources.modal.createPaymentLink.updateTitle]: "Update payment link",
    [StringResources.modal.createPaymentLink.copyTitle]: "Copy payment link",
    [StringResources.modal.createPaymentLink.updateButton]: "Update",
    [StringResources.modal.createPaymentLink.productsTitle]: "Products",
    [StringResources.modal.createPaymentLink.productsDescription]: "Description",
    [StringResources.modal.createPaymentLink.productsDiscount]: "Discount",
    [StringResources.modal.createPaymentLink.productsAmount]: "Amount",
    [StringResources.modal.createPaymentLink.productsQuantity]: "Quantity",
    [StringResources.modal.createPaymentLink.productsUnitPrice]: "Unit price",
    [StringResources.modal.createPaymentLink.productAmountWithoutDiscount]: "Amount without discount",
    [StringResources.modal.createPaymentLink.settingsTitle]: "Settings",
    [StringResources.modal.createPaymentLink.buyerInfoTitle]: "Buyer info",
    [StringResources.modal.createPaymentLink.salesTitle]: "Sales",
    [StringResources.modal.createPaymentLink.languageOptionsLabel]: "Default language",
    [StringResources.modal.createPaymentLink.paymentPageOptionsLabel]: "Payment page",
    [StringResources.modal.createPaymentLink.paymentLinkThemeOptionsLabel]: "Payment page theme",
    [StringResources.modal.createPaymentLink.payByLinkDescription]: "PayByLink Description",
    [StringResources.modal.createPaymentLink.payByLinkDescriptionToolTip]:
        "Description which serves to describe in more detail this payment link. It does not appear in payment page for the shopper.",
    [StringResources.modal.createPaymentLink.paymentPageThemeToolTip]: "Customization theme to be applied.",
    [StringResources.modal.createPaymentLink.paymentPageToolTip]:
        "E-Com agreement number and terminal ID to which the transactions will go through.",
    [StringResources.modal.createPaymentLink.purchaseReturnUrlToolTip]:
        "URL of the webpage to which shopper should be redirected upon completing the purchase.",
    [StringResources.modal.createPaymentLink.defaultLanguageToolTip]:
        "Initial language in which payment page is displayed to the shopper. Shopper can change language setting in the payment page itself.",
    [StringResources.modal.createPaymentLink.purchaseReturnUrl]: "Purchase Return URL",
    [StringResources.modal.createPaymentLink.hasExpirationDateOptionsLabel]: "Valid to",
    [StringResources.modal.createPaymentLink.doesNotExpire]: "Does not expire",
    [StringResources.modal.createPaymentLink.hasExpirationDate]: "Has expiration date",
    [StringResources.modal.createPaymentLink.hasExpirationDateRequired]: "Has expiration date is required",
    [StringResources.modal.createPaymentLink.validTo]: "Valid to",
    [StringResources.modal.createPaymentLink.hiddenLabel]: "Hidden",
    [StringResources.modal.createPaymentLink.requiredLabel]: "Required",
    [StringResources.modal.createPaymentLink.optionalabel]: "Optional",
    [StringResources.modal.createPaymentLink.address]: "Address",
    [StringResources.modal.createPaymentLink.city]: "City",
    [StringResources.modal.createPaymentLink.comments]: "Comments",
    [StringResources.modal.createPaymentLink.emailAddress]: "Email address",
    [StringResources.modal.createPaymentLink.fullName]: "Full name",
    [StringResources.modal.createPaymentLink.postCode]: "Post code",
    [StringResources.modal.createPaymentLink.telephone]: "Telephone",
    [StringResources.modal.createPaymentLink.ssn]: "SSN",
    [StringResources.modal.createPaymentLink.country]: "Country",
    [StringResources.modal.createPaymentLink.exactSalesCount]: "Exact sales count",
    [StringResources.modal.createPaymentLink.singleSales]: "Single sale",
    [StringResources.modal.createPaymentLink.exactSales]: "Exact sales",
    [StringResources.modal.createPaymentLink.unlimitedSales]: "Unlimited sales",
    [StringResources.modal.createPaymentLink.totalAmount]: "Total amount",
    [StringResources.modal.createPaymentLink.exactSalesCountRequired]: "Exact sales count required",
    [StringResources.modal.createPaymentLink.paymentPageRequired]: "Payment page is required",
    [StringResources.modal.createPaymentLink.paymentLinkThemeRequired]: "Payment link theme is required",
    [StringResources.modal.createPaymentLink.languageRequired]: "Language is required",
    [StringResources.modal.createPaymentLink.salesTypeRequired]: "Sales type is required",
    [StringResources.modal.createPaymentLink.dateToEmptyInfoMessage]:
        'This payment link will not expire - press "Create link" button again to confirm.',
    [StringResources.modal.createPaymentLink.createLinkButton]: "Create link",
    [StringResources.modal.createPaymentLink.descriptionRequired]: "Item description is required",
    [StringResources.modal.createPaymentLink.quantityIsRequired]: "Quantity is required",
    [StringResources.modal.createPaymentLink.quantityMustBeAtLeastOne]: "Quantity must be at least 1",
    [StringResources.modal.createPaymentLink.amountGreaterThanAmountWithoutDiscount]:
        "Amount cannot be greater than amount without discount",
    [StringResources.modal.createPaymentLink.totalAmountMustBeHigherThanZero]: "Total amount must be higher than zero",
    [StringResources.modal.createPaymentLink.unitPriceMustBeHigherThanZero]: "Unit price must be higher than zero",
    [StringResources.modal.createPaymentLink.validToRequired]: "Valid to is required",
    [StringResources.modal.createPaymentLink.successMessage]: "Successfully created payment link",
    [StringResources.modal.createPaymentLink.successUpdateMessage]: "SuccessFully updated payment link",
    [StringResources.modal.createPaymentLink.errorUpdateMessage]: "Error occurred while updating payment link",
    [StringResources.modal.createPaymentLink.errorMessage]: "Error occurred while creating payment link",
    [StringResources.modal.createPaymentLink.fetchPaymentPageError]:
        "Error occurred while fetching payment page options",
    [StringResources.modal.createPaymentLink.fetchPaymentLinkDetailsError]:
        "Error occurred while fetching payment link details data",
    [StringResources.modal.createPaymentLink.incorrectUrl]: "URL is not valid",

    [StringResources.modal.reversalAuthorization.reversal]: "Reversal",
    [StringResources.modal.reversalAuthorization.success]: "Authorization has been reversed successfully",
    [StringResources.modal.reversalAuthorization.error]: "Error occurred while reversing authorization",
    [StringResources.modal.reversalAuthorization.confirmReversalMessage]:
        "You will reverse {{amount}}\nAre you sure you want to reverse this authorization?",

    [StringResources.modal.disputeDetails.respond]: "Respond",
    [StringResources.modal.disputeDetails.acceptDispute]: "Accept dispute",
    [StringResources.modal.disputeDetails.originalTransaction]: "Original transaction",
    [StringResources.modal.disputeDetails.cardNumber]: "Card number",
    [StringResources.modal.disputeDetails.amount]: "Amount",

    [StringResources.modal.disputeDetails.purchaseDate]: "Purchase date",
    [StringResources.modal.disputeDetails.authorizationNumber]: "Authorization number",
    [StringResources.modal.disputeDetails.currency]: "Currency",
    [StringResources.modal.disputeDetails.originalBatchId]: "Original batch ID",
    [StringResources.modal.disputeDetails.settlement]: "Settlement",
    [StringResources.modal.disputeDetails.dbaName]: "DBA name",
    [StringResources.modal.disputeDetails.agreement]: "Agreement",
    [StringResources.modal.disputeDetails.transactionNumber]: "Transaction number",
    [StringResources.modal.disputeDetails.arn]: "ARN",
    [StringResources.modal.disputeDetails.originalTransactionDate]: "Original transaction date",
    [StringResources.modal.disputeDetails.transactionCode]: "Transaction code",
    [StringResources.modal.disputeDetails.transactionType]: "Transaction type",
    [StringResources.modal.disputeDetails.response]: "Response",
    [StringResources.modal.disputeDetails.date]: "Date",
    [StringResources.modal.disputeDetails.disputeEmailAddress]: "Email address",
    [StringResources.modal.disputeDetails.attachement]: "Attachement",
    [StringResources.modal.disputeDetails.responseText]: "Respond to this dispute step",
    [StringResources.modal.disputeDetails.case]: "Case",
    [StringResources.modal.disputeDetails.chargeback]: "Chargeback",
    [StringResources.modal.disputeDetails.representment]: "Representment",
    [StringResources.modal.disputeDetails.getError]: "Error occurred while fetching data",
    [StringResources.modal.disputeDetails.acceptError]: "Error occurred while accepting dispute",
    [StringResources.modal.disputeDetails.acceptSuccess]: "Dispute has been accepted successfully",
    [StringResources.modal.disputeDetails.noData]: "No data found, please try again later",
    [StringResources.modal.disputeDetails.reasonCode]: "Reason code",
    [StringResources.modal.disputeDetails.reasonCodeDescription]: "Reason code description",
    [StringResources.modal.disputeDetails.reasonCodeCategory]: "Reason code category",
    [StringResources.modal.disputeDetails.username]: "User",

    [StringResources.modal.payByLink.disableLinkTitle]: "Disable link?",
    [StringResources.modal.payByLink.disableLinkConfirmMessage]:
        "You are going to disable PayByLink {{description}} in the amount {{amount}} {{currency}} - are you sure?",
    [StringResources.modal.payByLink.disableLinkActionButton]: "Disable link",

    [StringResources.modal.apiKeys.disableKeyTitle]: "Disable key",
    [StringResources.modal.apiKeys.disableKeyMessage]:
        "You are going to disable API key {{keyName}} for agreement(s) {{contractNumbers}}. Action is immediate and cannot be reversed. Proceed?",
    [StringResources.modal.apiKeys.generateKeyTitle]: "Generate new API key",
    [StringResources.modal.apiKeys.generateKeyMessage]:
        "'Copy to Clipboard' will be enabled for 24 hours after the key is created. After this period 'Copy to Clipboard' is disabled by the system and new key shoudl be generated instead.",
    [StringResources.modal.apiKeys.subscribeToAllAgreements]: "Subscribe to all agreements",
    [StringResources.modal.apiKeys.generateKey]: "Generate key",
    [StringResources.modal.apiKeys.editKeyTitle]: "Edit key",
    [StringResources.modal.apiKeys.keyNameIsRequired]: "Key name is required",

    [StringResources.modal.webhooks.disableWebhookTitle]: "Disable webhook",
    [StringResources.modal.webhooks.disableWebhookMessage]:
        "You are going to disable Webhook {{webhookName}} for agreement(s) {{contractNumbers}}. Action is immediate and cannot be reversed. Proceed?",
    [StringResources.modal.webhooks.generateWebhookTitle]: "Register new webhook",
    [StringResources.modal.webhooks.generateWebhookMessage]:
        "'Copy to Clipboard' will be enabled for 24 hours after the webhook is created. After this period 'Copy to Clipboard' is disabled by the system and new webhook should be generated instead.",
    [StringResources.modal.webhooks.subscribeToAllAgreements]: "Subscribe to all agreements",
    [StringResources.modal.webhooks.generateWebhook]: "Register new webhook",
    [StringResources.modal.webhooks.editWebhookTitle]: "Edit webhook",

    [StringResources.errorBoundary.title]: "Oops something went wrong!",
    [StringResources.errorBoundary.subtitle]: "(Refreshing in {{restartInterval}})",
    [StringResources.errorBoundary.btnText]: "Refresh now",

    [StringResources.mainNavigation.dashboard]: "Dashboard",
    [StringResources.mainNavigation.payments]: "Payments",
    [StringResources.mainNavigation.terminals]: "Terminals",
    [StringResources.mainNavigation.agreements]: "Agreements",
    [StringResources.mainNavigation.dailyBalance]: "Daily balance",
    [StringResources.mainNavigation.settlements]: "Settlements",
    [StringResources.mainNavigation.batches]: "Batches",
    [StringResources.mainNavigation.transactions]: "Transactions",
    [StringResources.mainNavigation.authorizations]: "Authorizations",
    [StringResources.mainNavigation.exceptions]: "Exceptions",
    [StringResources.mainNavigation.ecomThemes]: "Themes",
    [StringResources.mainNavigation.users]: "Users",
    [StringResources.mainNavigation.electronicDocuments]: "Documents",
    [StringResources.mainNavigation.notifications]: "Notifications",
    [StringResources.mainNavigation.settings]: "Settings",
    [StringResources.mainNavigation.termsOfService]: "Terms of service",
    [StringResources.mainNavigation.apiKeys]: "Api keys",
    [StringResources.mainNavigation.logout]: "Log out",
    [StringResources.mainNavigation.overview]: "Overview",
    [StringResources.mainNavigation.payByLink]: "Pay by link",
    [StringResources.mainNavigation.access]: "Access",
    [StringResources.mainNavigation.ecom]: "E-COM",
    [StringResources.mainNavigation.webhooks]: "Webhooks",

    [StringResources.userMenu.back]: "Back",
    [StringResources.filter.buttonText]: "Filter",
    [StringResources.filter.totalSubtotal]: "Total and subtotal",
    [StringResources.filter.today]: "Today",
    [StringResources.filter.yesterday]: "Yesterday",
    [StringResources.filter.thisWeek]: "This week",
    [StringResources.filter.lastWeek]: "Last week",
    [StringResources.filter.thisMonth]: "This month",
    [StringResources.filter.lastMonth]: "Last month",
    [StringResources.filter.thisYear]: "This year",
    [StringResources.filter.clear]: "Clear",
    [StringResources.filter.search]: "Search",
    [StringResources.filter.dropdownSearchPlaceholder]: "Type to search",
    [StringResources.filter.currency]: "Currency",
    [StringResources.columnChooser.column]: "Column",
    [StringResources.columnChooser.unsavedChanges]: "You have unsaved changes!",
    [StringResources.columnChooser.save]: "Save & close",
    [StringResources.columnChooser.cancel]: "Cancel",
    [StringResources.columnChooser.selectAll]: "Select all",
    [StringResources.columnChooser.defaultView]: "Reset to default",

    [StringResources.reportDetails.boolean.yesLabel]: "Yes",
    [StringResources.reportDetails.boolean.noLabel]: "No",
    [StringResources.reportDetails.nonFinancial]: "Non financial transaction",
    [StringResources.table.cell.boolean.yesLabel]: "Yes",
    [StringResources.table.cell.boolean.noLabel]: "No",
    [StringResources.table.cell.copyToClipboardSuccess]: "Copied to clipboard",
    [StringResources.table.fallbackMessage.default]: "No {{pageName}} found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.agreements]: "No agreements found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.batches]: "No batches found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.payments]: "No payments found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.settlements]: "No settlements found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.transactions]: "No transactions found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.authorizations]: "No authorizations found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.disputes]: "No disputes found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.employees]: "No employees found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.electronicDocuments]: "No documents found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.dailyBalance]: "No daily balance found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.terminals]: "No terminals found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.none]: "No data found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.dashboard]: "No data found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.notFound]: "No data found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessageWithFilter]: " - try adjusting your filter settings",
    [StringResources.table.itemsPerPage]: "Items per page",
    [StringResources.report.export]: "Export data",
    [StringResources.report.print]: "Print",
    [StringResources.report.download]: "Download",
    [StringResources.toolTip.batches.searchBy]: "Search by:",
    [StringResources.toolTip.batches.batchId]: "Batch ID",
    [StringResources.toolTip.batches.settlementNumber]: "Settlement number",
    [StringResources.toolTip.batches.processorId]: "Processor ID",
    [StringResources.toolTip.batches.terminalId]: "Terminal ID",
    [StringResources.toolTip.payments.searchBy]: "Search by:",
    [StringResources.toolTip.payments.agreementNumber]: "Agreement number",
    [StringResources.toolTip.payments.dbaNames]: "DBA name(s)",
    [StringResources.toolTip.payments.settlements]: "Settlements",
    [StringResources.toolTip.settlements.searchBy]: "Search by:",
    [StringResources.toolTip.settlements.settlementNumber]: "Settlement number",
    [StringResources.toolTip.settlements.agreementId]: "Agreement ID",
    [StringResources.toolTip.settlements.dbaNames]: "DBA name(s)",
    [StringResources.toolTip.transactions.searchBy]: "Search by:",
    [StringResources.toolTip.transactions.transactionId]: "Transaction ID",
    [StringResources.toolTip.transactions.authNumber]: "Authorization number",
    [StringResources.toolTip.transactions.arn]: "ARN",
    [StringResources.toolTip.transactions.terminalId]: "Terminal ID",
    [StringResources.toolTip.transactions.physicalTerminalId]: "Physical terminal ID",
    [StringResources.toolTip.transactions.batchNumber]: "Batch number",
    [StringResources.toolTip.transactions.settlementNumber]: "Settlement number",
    [StringResources.toolTip.transactions.agreementId]: "Agreement ID",
    [StringResources.toolTip.transactions.partnerId]: "Partner ID",
    [StringResources.toolTip.transactions.externalTransactionId]: "External transaction ID",
    [StringResources.pages.agreements.title]: "Agreements",
    [StringResources.pages.agreements.viewSettlements]: "View settlements",
    [StringResources.pages.agreements.agreementNumber]: "Agreement number",
    [StringResources.pages.agreements.createdTime]: "Created time",
    [StringResources.pages.agreements.type]: "Type",
    [StringResources.pages.agreements.status]: "Status",
    [StringResources.pages.agreements.currency]: "Currency",
    [StringResources.pages.agreements.currencyCode]: "Currency code",
    [StringResources.pages.agreements.netBalance]: "Agreement balance",
    [StringResources.pages.agreements.grossBalance]: "Total balance",
    [StringResources.pages.agreements.grossBalanceToolTip]: "Total gross balance without fees",
    [StringResources.pages.agreements.merchantNumber]: "Merchant number",
    [StringResources.pages.agreements.merchantName]: "Merchant",
    [StringResources.pages.agreements.merchantRegistrationNumber]: "Registration number",
    [StringResources.pages.agreements.isCardPresent]: "Agreement type",
    [StringResources.pages.authorizations.title]: "Authorizations",
    [StringResources.pages.authorizations.search]: "Authorization number",
    [StringResources.pages.authorizations.dateFrom]: "Date from",
    [StringResources.pages.authorizations.dateTo]: "Date to",
    [StringResources.pages.authorizations.amountFrom]: "Amount from",
    [StringResources.pages.authorizations.amountTo]: "Amount to",
    [StringResources.pages.authorizations.cardNumber]: "Card number",
    [StringResources.pages.authorizations.agreementId]: "Agreement ID",
    [StringResources.pages.authorizations.agreementNumber]: "Agreement number",
    [StringResources.pages.authorizations.terminalId]: "Terminal ID",
    [StringResources.pages.authorizations.authorizationCode]: "Authorization code",
    [StringResources.pages.authorizations.lifeCycleId]: "Life cycle ID",
    [StringResources.pages.authorizations.amount]: "Amount",
    [StringResources.pages.authorizations.state]: "State",
    [StringResources.pages.authorizations.par]: "PAR",
    [StringResources.pages.authorizations.authorizationDateTime]: "Authorization date",
    [StringResources.pages.authorizations.transactionId]: "Transaction ID",
    [StringResources.pages.authorizations.authorizationId]: "Authorization ID",
    [StringResources.pages.authorizations.authorizationStatus]: "Status",
    [StringResources.pages.authorizations.responseCode]: "Response code",
    [StringResources.pages.authorizations.dbaName]: "DBA name",
    [StringResources.pages.authorizations.deviceId]: "Device ID",
    [StringResources.pages.authorizations.isRefund]: "Is refund",
    [StringResources.pages.authorizations.scheme]: "Scheme",
    [StringResources.pages.authorizations.merchantName]: "Merchant",
    [StringResources.pages.authorizations.details.title]: "Authorizations",
    [StringResources.pages.authorizations.details.reverse]: "Reverse",
    [StringResources.pages.authorizations.additionalData]: "Additional data",
    [StringResources.pages.authorizations.viewTransactions]: "View transactions",
    [StringResources.pages.authorizations.externalTransactionId]: "External transaction ID",
    [StringResources.pages.authorizations.paymentAccountReference]: "PAR",
    [StringResources.pages.batches.title]: "Batches",
    [StringResources.pages.batches.viewTransactions]: "View transactions",
    [StringResources.pages.batches.feesAndAdditions]: "Fees and additions",
    [StringResources.pages.batches.serviceCharges]: "Service charges",
    [StringResources.pages.batches.refund]: "Refund",
    [StringResources.pages.batches.search]: "Search batches",
    [StringResources.pages.batches.allAgreements]: "All agreements",
    [StringResources.pages.batches.status]: "Status",
    [StringResources.pages.batches.dateFrom]: "Date from",
    [StringResources.pages.batches.dateTo]: "Date to",
    [StringResources.pages.batches.amountFrom]: "Amount from",
    [StringResources.pages.batches.amountTo]: "Amount to",
    [StringResources.pages.batches.settledFrom]: "Settled from",
    [StringResources.pages.batches.settledTo]: "Settled to",
    [StringResources.pages.batches.paidFrom]: "Paid from",
    [StringResources.pages.batches.paidTo]: "Paid to",
    [StringResources.pages.batches.settlementType]: "Settlement type",
    [StringResources.pages.batches.agreementId]: "Agreement ID",
    [StringResources.pages.batches.dbaName]: "DBA name",
    [StringResources.pages.batches.date]: "Registration date",
    [StringResources.pages.batches.batch]: "Batch",
    [StringResources.pages.batches.batchTotal]: "Batch total",
    [StringResources.pages.batches.transactions]: "Transactions",
    [StringResources.pages.batches.settlementNumber]: "Settlement number",
    [StringResources.pages.batches.processorId]: "Processor ID",
    [StringResources.pages.batches.chargeback]: "Chargeback",
    [StringResources.pages.batches.currency]: "Currency",
    [StringResources.pages.batches.gross]: "Gross",
    [StringResources.pages.batches.netAmount]: "Net amount",
    [StringResources.pages.batches.paid]: "Paid",
    [StringResources.pages.batches.partnerId]: "Partner ID",
    [StringResources.pages.batches.settled]: "Settled",
    [StringResources.pages.batches.terminalId]: "Terminal ID",
    [StringResources.pages.batches.details.grossPurchase]: "Gross purchase",
    [StringResources.pages.batches.details.refundAmount]: "Refund amount",
    [StringResources.pages.batches.details.reversals]: "Reversals",
    [StringResources.pages.batches.details.grossBatch]: "Gross amount",
    [StringResources.pages.batches.details.feeAmount]: "Fee amount",
    [StringResources.pages.batches.details.transactionFee]: "Transaction fee",
    [StringResources.pages.batches.details.joiningFee]: "Joining fee",
    [StringResources.pages.batches.details.chargebackFees]: "Chargeback fees",
    [StringResources.pages.batches.details.swiftFees]: "Swift fees",
    [StringResources.pages.batches.details.rejected]: "Rejected",
    [StringResources.pages.batches.details.representment]: "Representment",
    [StringResources.pages.batches.details.posRentalFee]: "POS rental fee",
    [StringResources.pages.batches.details.preArbitration]: "Pre-arbitration",
    [StringResources.pages.batches.details.netBatch]: "Net amount",
    [StringResources.pages.batches.details.breakdown]: "Breakdown",
    [StringResources.pages.batches.details.brandType]: "Scheme",
    [StringResources.pages.batches.details.transactions]: "Transactions",
    [StringResources.pages.batches.details.viewSettlement]: "View settlement",

    [StringResources.pages.dailyBalance.title]: "Daily balance",
    [StringResources.pages.dailyBalance.agreementNumber]: "Agreement number",
    [StringResources.pages.dailyBalance.dateFrom]: "Date from",
    [StringResources.pages.dailyBalance.dateTo]: "Date to",
    [StringResources.pages.dailyBalance.merchantNumber]: "Merchant number",
    [StringResources.pages.dailyBalance.partnerId]: "Partner ID",
    [StringResources.pages.dailyBalance.currency]: "Currency",
    [StringResources.pages.dailyBalance.netBalance]: "Net Balance",
    [StringResources.pages.dailyBalance.netBalanceTooltip]: "Daily accumulated net balance of agreement",
    [StringResources.pages.dailyBalance.grossBalance]: "Gross Balance",
    [StringResources.pages.dailyBalance.grossBalanceTooltip]: "Daily accumulated gross balance of transactions",
    [StringResources.pages.dailyBalance.paidAmount]: "Net Payments",
    [StringResources.pages.dailyBalance.paidAmountTooltip]: "Daily net payments amount paid out to the merchant",
    [StringResources.pages.dailyBalance.date]: "Date",
    [StringResources.pages.dashboard.title]: "Dashboard",
    [StringResources.pages.dashboard.date]: "Date",
    [StringResources.pages.dashboard.setSalesTarget]: "Set sales target",
    [StringResources.pages.dashboard.targetAmount]: "Target amount",

    [StringResources.pages.overview.title]: "Overview",
    [StringResources.pages.overview.turnover.title]: "Turnover",
    [StringResources.pages.overview.turnover.region]: "Region",
    [StringResources.pages.overview.turnover.numberOfTransactions]: "Number of transactions",
    [StringResources.pages.overview.turnover.numberOfTransactionsPercentage]: "Number of transactions % of total",
    [StringResources.pages.overview.turnover.volumeOfTransactions]: "Volume of transactions",
    [StringResources.pages.overview.turnover.volumeOfTransactionsPercentage]: "Volume of transactions % of total",
    [StringResources.pages.overview.turnover.total]: "Total",
    [StringResources.pages.overview.turnover.ecomContract]: "Ecom",
    [StringResources.pages.overview.turnover.posContract]: "Pos",
    [StringResources.pages.overview.turnover.all]: "All",
    [StringResources.pages.overview.turnover.fetchData]: "Fetch data",
    [StringResources.pages.overview.turnover.dateFrom]: "Date from",
    [StringResources.pages.overview.turnover.dateTo]: "Date to",
    [StringResources.pages.overview.turnover.error]: "Error occurred while fetching data",
    [StringResources.pages.overview.turnover.contract]: "Agreement",

    [StringResources.pages.electronicDocuments.title]: "Documents",
    [StringResources.pages.electronicDocuments.date]: "Date",
    [StringResources.pages.electronicDocuments.documentName]: "Document name",
    [StringResources.pages.electronicDocuments.dateCreated]: "Date created",
    [StringResources.pages.electronicDocuments.documentType]: "Document type",
    [StringResources.pages.electronicDocuments.agreementNumber]: "Agreement number",
    [StringResources.pages.electronicDocuments.ssn]: "SSN",
    [StringResources.pages.electronicDocuments.authorizationDateTime]: "Authorization date",
    [StringResources.pages.electronicDocuments.amount]: "Amount",
    [StringResources.pages.electronicDocuments.dbaName]: "DBA name",
    [StringResources.pages.electronicDocuments.cardNumber]: "Card number",
    [StringResources.pages.electronicDocuments.details.download]: "Download",
    [StringResources.pages.electronicDocuments.details.fileDownloadError]: "Download error",
    [StringResources.pages.electronicDocuments.details.fileDownloadSuccess]: "Downloaded",
    [StringResources.pages.electronicDocuments.printStarted]: "In progress",

    [StringResources.pages.payments.title]: "Payments",
    [StringResources.pages.payments.breakdown]: "Settlement breakdown",
    [StringResources.pages.payments.breakdownNoRecords]: "No records found.",
    [StringResources.pages.payments.settlement]: "Settlement",
    [StringResources.pages.payments.netPurchase]: "Net purchase",
    [StringResources.pages.payments.payout]: "Payout",
    [StringResources.pages.payments.search]: "Search payments",
    [StringResources.pages.payments.dateFrom]: "Date from",
    [StringResources.pages.payments.dateTo]: "Date to",
    [StringResources.pages.payments.amountFrom]: "Amount from",
    [StringResources.pages.payments.amountTo]: "Amount to",
    [StringResources.pages.payments.agreementNumber]: "Agreement number",
    [StringResources.pages.payments.currency]: "Currency",
    [StringResources.pages.payments.swift]: "Swift",
    [StringResources.pages.payments.merchantName]: "Merchant",
    [StringResources.pages.payments.iban]: "IBAN",
    [StringResources.pages.payments.payoutAmount]: "Payout amount",
    [StringResources.pages.payments.feeAmounts]: "Fee amount",
    [StringResources.pages.payments.grossAmounts]: "Gross amount",
    [StringResources.pages.payments.paidDate]: "Paid date",
    [StringResources.pages.payments.scheduled]: "Scheduled",
    [StringResources.pages.payments.partnerId]: "Partner ID",
    [StringResources.pages.payments.partnerName]: "Partner name",
    [StringResources.pages.payments.dbaNames]: "DBA names",
    [StringResources.pages.payments.ssn]: "SSN",
    [StringResources.pages.payments.settlementType]: "Settlement type",
    [StringResources.pages.payments.settlements]: "Settlements",
    [StringResources.pages.payments.printReportError]: "Download error",
    [StringResources.pages.payments.printStarted]: "In progress",

    [StringResources.pages.settlements.title]: "Settlements",
    [StringResources.pages.settlements.breakdown]: "Breakdown",
    [StringResources.pages.settlements.search]: "Search settlements",
    [StringResources.pages.settlements.paidDateFrom]: "Paid date from",
    [StringResources.pages.settlements.paidDateTo]: "Paid date to",
    [StringResources.pages.settlements.createdDateFrom]: "Created date from",
    [StringResources.pages.settlements.createdDateTo]: "Created date to",
    [StringResources.pages.settlements.amountFrom]: "Amount from",
    [StringResources.pages.settlements.amountTo]: "Amount to",
    [StringResources.pages.settlements.allAgreements]: "All agreements",
    [StringResources.pages.settlements.currency]: "Currency",
    [StringResources.pages.settlements.grossPurchase]: "Gross purchase",
    [StringResources.pages.settlements.grossSettlement]: "Gross settlement",
    [StringResources.pages.settlements.feeAmount]: "Fee amount",
    [StringResources.pages.settlements.totalFees]: "Total fees",
    [StringResources.pages.settlements.netSettlement]: "Net settlement",
    [StringResources.pages.settlements.batches]: "Batches",
    [StringResources.pages.settlements.viewBatches]: "View batches",
    [StringResources.pages.settlements.viewTransactions]: "View transactions",
    [StringResources.pages.settlements.dbaName]: "DBA name",
    [StringResources.pages.settlements.gross]: "Gross",
    [StringResources.pages.settlements.netPurchase]: "Net purchase",
    [StringResources.pages.settlements.date]: "Date",
    [StringResources.pages.settlements.batch]: "Batch",
    [StringResources.pages.settlements.batchNoRecords]: "No records found.",
    [StringResources.pages.settlements.batchTotal]: "Batch total",
    [StringResources.pages.settlements.batchNumber]: "Batch number",
    [StringResources.pages.settlements.status]: "Status",
    [StringResources.pages.settlements.settlementFee]: "Settlement fee",
    [StringResources.pages.settlements.paid]: "Paid",
    [StringResources.pages.settlements.agreementId]: "Agreement ID",
    [StringResources.pages.settlements.settlementType]: "Settlement type",
    [StringResources.pages.settlements.refundAmount]: "Refunds",
    [StringResources.pages.settlements.createdDate]: "Date created",
    [StringResources.pages.settlements.scheduledDate]: "Scheduled",
    [StringResources.pages.settlements.settlementNumber]: "Settlement number",
    [StringResources.pages.settlements.batchesCount]: "Batches",
    [StringResources.pages.settlements.transactionsCount]: "Transactions",
    [StringResources.pages.settlements.cumulativeFees]: "Cumulative fees",
    [StringResources.pages.settlements.payout]: "Payout",
    [StringResources.pages.settlements.partnerId]: "Partner ID",
    [StringResources.pages.settlements.partnerName]: "Partner name",
    [StringResources.pages.settlements.deduction]: "Deduction",
    [StringResources.pages.settlements.representment]: "Representments",
    [StringResources.pages.settlements.chargeback]: "Chargeback",
    [StringResources.pages.settlements.rollingReserve]: "Rolling reserves",
    [StringResources.pages.settlements.rollingRelease]: "Rolling release",
    [StringResources.pages.settlements.chargebackFees]: "Chargeback fees",
    [StringResources.pages.settlements.swiftFees]: "Swift fees",
    [StringResources.pages.settlements.transactionFee]: "Transaction fee",
    [StringResources.pages.settlements.joiningFee]: "Joining fee",
    [StringResources.pages.settlements.rejected]: "Rejected",
    [StringResources.pages.settlements.addedSum]: "Added sum",
    [StringResources.pages.settlements.deductedSum]: "Deducted sum",
    [StringResources.pages.settlements.reversals]: "Reversals",
    [StringResources.pages.settlements.preArbitration]: "Pre-arbitration",
    [StringResources.pages.settlements.posRentalFee]: "POS rental fee",
    [StringResources.pages.settlements.exceptionCorrection]: "Exception correction",
    [StringResources.pages.settlements.dccCommission]: "DCC commission",
    [StringResources.pages.settlements.dbaNames]: "DBA names",
    [StringResources.pages.settlements.ssn]: "SSN",
    [StringResources.pages.settlements.claim]: "Claim",
    [StringResources.pages.settlements.claimDueDate]: "Claim due date",
    [StringResources.pages.settlements.aukakronurFee]: "Aukakronur fee",
    [StringResources.pages.settlements.otherFees]: "Other fees",
    [StringResources.pages.settlements.gomobileFee]: "Gomobile fee",
    [StringResources.pages.settlements.gomobileInitialFee]: "Gomobile initial fee",
    [StringResources.pages.settlements.preAuthorizationFee]: "Pre authorization fee",
    [StringResources.pages.settlements.monthlyFee]: "Monthly fee",
    [StringResources.pages.settlements.authorizationFee]: "Authorization fee",
    [StringResources.pages.settlements.minimumMonthlyServiceFee]: "Min. monthly service fee",
    [StringResources.pages.settlements.cardNotPresentFee]: "Card not present fee",
    [StringResources.pages.settlements.pciFee]: "PCI fee",
    [StringResources.pages.settlements.mobileAirTimeFee]: "Mobile airtime fee",
    [StringResources.pages.settlements.ecomGatewayMonthlyFee]: "Ecom gateway monthly fee",
    [StringResources.pages.settlements.ecomGatewayTransactionFee]: "Ecom gateway trans. fee",
    [StringResources.pages.settlements.merchantName]: "Merchant",
    [StringResources.pages.settlements.print]: "Print",
    [StringResources.pages.settlements.printReportError]: "Download error",
    [StringResources.pages.settlements.viewPayment]: "View payment",
    [StringResources.pages.settlements.hasInvoice]: "Invoiced",
    [StringResources.pages.transactions.title]: "Transactions",
    [StringResources.pages.transactions.search]: "Search Transactions",
    [StringResources.pages.transactions.period]: "Period",
    [StringResources.pages.transactions.purchaseDateFrom]: "Purchase date from",
    [StringResources.pages.transactions.purchaseDateTo]: "Purchase date to",
    [StringResources.pages.transactions.amountFrom]: "Amount from",
    [StringResources.pages.transactions.amountTo]: "Amount to",
    [StringResources.pages.transactions.cardNumber]: "Card number",
    [StringResources.pages.transactions.settlementNumber]: "Settlement number",
    [StringResources.pages.transactions.cardType]: "Card Type",
    [StringResources.pages.transactions.cardIssuer]: "Card Issuer",
    [StringResources.pages.transactions.registrationDateFrom]: "Registration date from",
    [StringResources.pages.transactions.registrationDateTo]: "Registration date to",
    [StringResources.pages.transactions.paidDateFrom]: "Paid date from",
    [StringResources.pages.transactions.paidDateTo]: "Paid date to",
    [StringResources.pages.transactions.settlementType]: "Settlement Type",
    [StringResources.pages.transactions.transactionId]: "Transaction ID",
    [StringResources.pages.transactions.authNumber]: "Auth number",
    [StringResources.pages.transactions.transactionCode]: "Transaction code",
    [StringResources.pages.transactions.authorizationDate]: "Authorization date",
    [StringResources.pages.transactions.merchant]: "Merchant",
    [StringResources.pages.transactions.dbaName]: "DBA name",
    [StringResources.pages.transactions.referenceData]: "Reference data",
    [StringResources.pages.transactions.externalTransactionId]: "External transaction ID",
    [StringResources.pages.transactions.purchaseDate]: "Purchase date",
    [StringResources.pages.transactions.originalAmount]: "Original amount",
    [StringResources.pages.transactions.gross]: "Gross",
    [StringResources.pages.transactions.netAmount]: "Net amount",
    [StringResources.pages.transactions.schemeFee]: "Scheme fee",
    [StringResources.pages.transactions.schemeFeeCurrency]: "Scheme fee currency",
    [StringResources.pages.transactions.currency]: "Currency",
    [StringResources.pages.transactions.secure3D]: "3D Secure",
    [StringResources.pages.transactions.batch]: "Batch",
    [StringResources.pages.transactions.registrationNumber]: "Registration number",
    [StringResources.pages.transactions.reasonCode]: "Reason code",
    [StringResources.pages.transactions.cardholderAmount]: "Cardholder amount",
    [StringResources.pages.transactions.cardholderCurency]: "Cardholder currency",
    [StringResources.pages.transactions.cashbackAmount]: "Cashback amount",
    [StringResources.pages.transactions.agreementId]: "Agreement ID",
    [StringResources.pages.transactions.merchantBucket]: "Merchant bucket",
    [StringResources.pages.transactions.physicalTermianlId]: "Physical terminal ID",
    [StringResources.pages.transactions.interchange]: "Interchange",
    [StringResources.pages.transactions.lifeCycleId]: "LifeCycle ID",
    [StringResources.pages.transactions.paid]: "Paid",
    [StringResources.pages.transactions.terminalId]: "Terminal ID",
    [StringResources.pages.transactions.isFinancial]: "Is financial?",
    [StringResources.pages.transactions.arn]: "ARN",
    [StringResources.pages.transactions.fees]: "Fees",
    [StringResources.pages.transactions.originalCurrency]: "Original currency",
    [StringResources.pages.transactions.partnerId]: "Partner ID",
    [StringResources.pages.transactions.registrationDate]: "Registration date",
    [StringResources.pages.transactions.transactionType]: "Transaction type",
    [StringResources.pages.transactions.details.title]: "Transactions",
    [StringResources.pages.transactions.details.refund]: "Refund",
    [StringResources.pages.transactions.details.print]: "Print",
    [StringResources.pages.transactions.details.refundReportError]: "Error occurred while downloading refund report",
    [StringResources.pages.transactions.details.transactionReceiptError]:
        "Error occurred while downloading transaction receipt",
    [StringResources.pages.transactions.merchantFee]: "Merchant Fee",
    [StringResources.pages.transactions.schemeFeePercent]: "Scheme fee Percent",
    [StringResources.pages.transactions.schemeFeeBase]: "Scheme fee Base",
    [StringResources.pages.transactions.schemeFeeFixed]: "Scheme fee Fixed",
    [StringResources.pages.transactions.authorizationCode]: "Authorization code",
    [StringResources.pages.transactions.cardEntryModeType]: "Card entry mode type",
    [StringResources.pages.transactions.authenticationMethodType]: "Authentication method type",
    [StringResources.pages.transactions.authorizationMethodType]: "Authorization method type",
    [StringResources.pages.transactions.additionalData]: "Additional data",
    [StringResources.pages.transactions.isReversal]: "Is reversal",
    [StringResources.pages.transactions.refundStatus]: "Refund status",
    [StringResources.pages.transactions.refundAmount]: "Refund amount",
    [StringResources.pages.transactions.transactionRefundBreakdown.title]: "Refund requests",
    [StringResources.pages.transactions.transactionRefundBreakdown.viewRefunds]: "View refunds",
    [StringResources.pages.transactions.transactionRefundBreakdown.noRecords]: "No refunds",
    [StringResources.pages.transactions.transactionRefundBreakdown.originalTransaction]: "Original transaction",
    [StringResources.pages.transactions.transactionRefundBreakdown.refundedOf]:
        "Refunded {{amount}} of {{totalAmount}}",
    [StringResources.pages.transactions.transactionRefundBreakdown.user]: "User",
    [StringResources.pages.transactions.transactionRefundBreakdown.date]: "Date",
    [StringResources.pages.transactions.transactionRefundBreakdown.amount]: "Amount",
    [StringResources.pages.transactions.viewAuthorization]: "View authorization",

    [StringResources.pages.employees.title]: "Users",
    [StringResources.pages.employees.permissions]: "Permissions",
    [StringResources.pages.employees.apiKey]: "API Key",
    [StringResources.pages.employees.table.nameHeaderLabel]: "Name",
    [StringResources.pages.employees.table.usernameHeaderLabel]: "Email",
    [StringResources.pages.employees.table.statusHeaderLabel]: "Status",
    [StringResources.pages.employees.table.employeeRoleLabel]: "User Role",
    [StringResources.pages.employees.table.contractsHeaderLabel]: "Agreement",
    [StringResources.pages.employees.table.statusActiveLabel]: "Active",
    [StringResources.pages.employees.table.statusInactiveLabel]: "Deactivated",
    [StringResources.pages.employees.create.buttonText]: "Add user",
    [StringResources.pages.employees.create.title]: "Add new user",
    [StringResources.pages.employees.create.permissions]: "Permissions",
    [StringResources.pages.employees.create.permissionsPage]: "Page",
    [StringResources.pages.employees.create.confirm]: "Add user",
    [StringResources.pages.employees.create.close]: "Close",
    [StringResources.pages.employees.create.formEmailInputLabel]: "Username",
    [StringResources.pages.employees.create.formContractInputLabel]: "Agreements",
    [StringResources.pages.employees.create.formButtonCaption]: "Create",
    [StringResources.pages.employees.create.formEmailValidationMsg]: "Please enter a valid email address",
    [StringResources.pages.employees.create.formUsernameAlreadyHasAccessValidationMsg]:
        "User already has access to this merchant.",
    [StringResources.pages.employees.create.successMsg]: "Successfully added new user",

    [StringResources.pages.employees.edit.title]: "Edit user",
    [StringResources.pages.employees.edit.permissions]: "Permissions",
    [StringResources.pages.employees.edit.permissionsPage]: "Page",
    [StringResources.pages.employees.edit.confirm]: "Edit",
    [StringResources.pages.employees.edit.deactivate]: "Deactivate",
    [StringResources.pages.employees.edit.close]: "Close",
    [StringResources.pages.employees.edit.formContractInputLabel]: "Agreements",
    [StringResources.pages.employees.edit.successMsg]: "Successfully edited user",
    [StringResources.pages.employees.edit.errorMsg]: "Error while editing user",
    [StringResources.pages.employees.edit.errorApiKeyMsg]: "Error while fetching API key.",
    [StringResources.pages.employees.edit.successDeactivateMsg]: "Successfully deactivated user",
    [StringResources.pages.employees.edit.formRoleLabel]: "Role",
    [StringResources.pages.employees.edit.formAdminLabel]: "Admin",
    [StringResources.pages.employees.edit.formUserLabel]: "User",
    [StringResources.pages.employees.edit.contracts]: "Agreements",
    [StringResources.pages.employees.edit.formAdminEmployeeHasAccessToAllContracts]:
        "Admin employee has access to all agreements.",
    [StringResources.pages.employees.edit.formAdminEmployeeHasAllPermissions]: "Admin employee has all permissions.",

    [StringResources.pages.employees.details.title]: "Details",
    [StringResources.pages.employees.details.edit]: "Edit",
    [StringResources.pages.employees.details.employeeId]: "ID",
    [StringResources.pages.employees.details.username]: "Username",
    [StringResources.pages.employees.details.name]: "Name",
    [StringResources.pages.employees.details.createdTime]: "Created time",
    [StringResources.pages.employees.details.archivedTime]: "Archived time",
    [StringResources.pages.employees.details.verificationTime]: "Verification time",
    [StringResources.pages.employees.details.status]: "Status",
    [StringResources.pages.employees.details.lastLoginDate]: "Last login date",
    [StringResources.pages.employees.details.contracts]: "Agreements",
    [StringResources.pages.employees.details.languageType]: "Language type",
    [StringResources.pages.employees.details.showApiKey]: "Show API password",
    [StringResources.pages.disputes.title]: "Disputes",
    [StringResources.pages.disputes.purchaseDate]: "Purchase date",
    [StringResources.pages.disputes.agreement]: "Agreement",
    [StringResources.pages.disputes.cardNumber]: "Card number",
    [StringResources.pages.disputes.registrationDate]: "Registration date",
    [StringResources.pages.disputes.gross]: "Gross",
    [StringResources.pages.disputes.currency]: "Currency",
    [StringResources.pages.disputes.settlementNumber]: "Settlement Number",
    [StringResources.pages.disputes.arn]: "ARN",
    [StringResources.pages.disputes.uploadDispute]: "Upload Dispute",
    [StringResources.pages.disputes.comment]: "Comment",
    [StringResources.pages.disputes.emailAddress]: "Email address",
    [StringResources.pages.disputes.cardType]: "Card type",
    [StringResources.pages.disputes.transactionType]: "Transaction type",
    [StringResources.pages.disputes.details.title]: "Disputes",
    [StringResources.pages.disputes.details.edit]: "Upload",
    [StringResources.pages.disputes.disputeType]: "Dispute type",
    [StringResources.pages.disputes.batchNumber]: "Batch number",
    [StringResources.pages.disputes.transactionNumber]: "Transaction number",
    [StringResources.pages.disputes.isChargeback]: "Is chargeback",
    [StringResources.pages.disputes.isFinancial]: "Is financial",
    [StringResources.pages.disputes.isRepresentment]: "Is representment",
    [StringResources.pages.disputes.isReversal]: "Is reversal",
    [StringResources.pages.disputes.uploadDisputeSuccess]: "Successfully uploaded dispute",
    [StringResources.pages.disputes.uploadDisputeError]: "Error uploading dispute",
    [StringResources.pages.disputes.dateFrom]: "Date from",
    [StringResources.pages.disputes.dateTo]: "Date to",
    [StringResources.pages.disputes.emailInvalid]: "Email is invalid",
    [StringResources.pages.disputes.confirmationEmailWillBeSent]:
        "By pressing “Confirm defense”, your response will be sent for review and any further file uploading will be unavailable. Confirmation will be sent to email:",
    [StringResources.pages.disputes.fileUploadErrorTitle]: "File upload failed",
    [StringResources.pages.disputes.fileUploadErrorDefault]: "Something went wrong. Please try again later.",
    [StringResources.pages.disputes.submitLater]: "Later",
    [StringResources.pages.disputes.confirmSubmit]:
        "Are you sure you want to submit the dispute? By pressing “Confirm defense”, your response will be sent for review and any further file uploading will be unavailable.",
    [StringResources.pages.disputes.confirmSubmitTitle]: "Submit Dispute?",
    [StringResources.pages.disputes.submit]: "Confirm defense",
    [StringResources.pages.disputes.confirmRemoveTitle]: "Remove documents?",
    [StringResources.pages.disputes.confirmRemoveText]: "All uploaded documents will be removed.",
    [StringResources.pages.disputes.confirmRemoveButton]: "Remove documents",
    [StringResources.pages.disputes.removeDocumentsError]: "Error while removing documents",
    [StringResources.pages.disputes.removeDocumentsSuccess]: "Successfully removed documents",
    [StringResources.pages.disputes.acceptDisputeSuccess]: "Successfully confirmed the defense of the dispute",
    [StringResources.pages.disputes.acceptDisputeError]: "Error while confirming the defense of the dispute",
    [StringResources.pages.disputes.removeDocuments]: "Remove documents",
    [StringResources.pages.disputes.retry]: "Retry",
    [StringResources.pages.disputes.remove]: "Remove",
    [StringResources.pages.disputes.fileLimitations]:
        ".jpg, .jpeg and .tiff files up to 10MB, and .pdf files up to 2MB are supported.",
    [StringResources.pages.disputes.fileSizeError]: "File is too large",
    [StringResources.pages.disputes.commentRequired]: "Comment is required",
    [StringResources.pages.disputes.emailRequired]: "Email is required",

    [StringResources.pages.disputeNew.title]: "Exceptions",
    [StringResources.pages.disputeNew.currency]: "Currency",
    [StringResources.pages.disputeNew.filter]: "Filter",
    [StringResources.pages.disputeNew.columnChooser]: "Column chooser",
    [StringResources.pages.disputeNew.reasonCode]: "Reason code",
    [StringResources.pages.disputeNew.reasonCodeValue]: "Reason code value",
    [StringResources.pages.disputeNew.reasonCodeCategory]: "Reason code category",
    [StringResources.pages.disputeNew.status]: "Status",
    [StringResources.pages.disputeNew.settlementAmount]: "Settlement amount",
    [StringResources.pages.disputeNew.transactionAmount]: "Transaction amount",
    [StringResources.pages.disputeNew.update]: "Update",
    [StringResources.pages.disputeNew.date]: "Date",
    [StringResources.pages.disputeNew.type]: "Type",
    [StringResources.pages.disputeNew.caseNumber]: "Case number",
    [StringResources.pages.disputeNew.disputeType]: "Dispute type",
    [StringResources.pages.disputeNew.dateFrom]: "Date from",
    [StringResources.pages.disputeNew.dateTo]: "Date to",
    [StringResources.pages.disputeNew.arn]: "Arn",

    [StringResources.pages.notFound.title]: "Page not found",
    [StringResources.pages.notFound
        .description]: `The page you are trying to view does not exist or you do not have permission to view it.`,
    [StringResources.pages.notFound.link]: "Back to merchant portal",
    [StringResources.pages.none.title]: "None",
    [StringResources.pages.settings.title]: "Settings",
    [StringResources.pages.settings.generalTab]: "General",
    [StringResources.pages.settings.emailsAndNotificationsTab]: "Emails & notifications",

    [StringResources.pages.settings.changePasswordTab]: "Change password",
    [StringResources.pages.settings.accountAndSecurity.title]: "Account and Security",
    [StringResources.pages.settings.accountAndSecurity.details]: "Details",
    [StringResources.pages.settings.accountAndSecurity.changePasswordTitle]: "Change password",
    [StringResources.pages.settings.accountAndSecurity.username]: "Signed in as:",
    [StringResources.pages.settings.general.defaultLanguage]: "Default language",
    [StringResources.pages.settings.changePassword.oldPassword]: "Old password",
    [StringResources.pages.settings.changePassword.newPassword]: "New password",
    [StringResources.pages.settings.changePassword.repeatNewPassword]: "Repeat new password",
    [StringResources.pages.settings.changePassword.saveNewPassword]: "Save new password",
    [StringResources.pages.settings.changePassword.confirmNewPassword]: "Confirm new password",
    [StringResources.pages.settings.changePassword.currentPassword]: "Current password",
    [StringResources.pages.settings.emailsAndNotifications.statementsTitle]: "Statements",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioTitle]:
        "Receive an email notification when a statement is generated. You will only receive statements for agreements you have permission to view",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioPdfEmail]:
        "Email with statement document (pdf)",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioNotificationEmail]:
        "Email notification only (no document)",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioNoEmail]: "No email",
    [StringResources.pages.settings.emailsAndNotifications.radioChange.statementsRadioError]:
        "Error while updating notification settings",
    [StringResources.pages.settings.emailsAndNotifications.radioChange.statementsRadioSuccess]:
        "Successfully updated notification settings",

    [StringResources.pages.settings.emailsAndNotifications.radioChange.disputeRadioError]:
        "Error while updating notification settings",
    [StringResources.pages.settings.emailsAndNotifications.radioChange.disputeRadioSuccess]:
        "Successfully updated notification settings",
    [StringResources.pages.settings.emailsAndNotifications.disputeTitle]: "Disputes",
    [StringResources.pages.settings.emailsAndNotifications.disputeRadioTitle]:
        "Receive an email notification when a dispute is generated. You will only receive dispute for agreements you have permission to view",
    [StringResources.pages.settings.emailsAndNotifications.disputeRadioNotificationEmail]: "Email notification",
    [StringResources.pages.settings.emailsAndNotifications.disputeRadioNoEmail]: "No email",

    [StringResources.pages.terms.title]: "Terms of service",
    [StringResources.pages.terms.link]: "Download pdf document",
    [StringResources.pages.terms.conditions]: "I accept Stramur's terms of service",
    [StringResources.pages.terms.back]: "Logout",
    [StringResources.pages.terms.acceptTerms]: "Continue",
    [StringResources.pages.terms.selectMerchant]: "Log in as",
    [StringResources.pages.terms.descriptionFirst]: "Please read and accept",
    [StringResources.pages.terms.descriptionLink]: "Straumur's Terms of Service",
    [StringResources.pages.terms.descriptionSecond]: "(valid from 1. January 2023) to use the Merchant Portal.",

    [StringResources.pages.terms.userMessageAcceptTerms]:
        "In order to continue using merchant portal, your organization's administrator needs to log in and accepts the terms of service",
    [StringResources.pages.terms.newTermsAvailable.first]: "Terms and conditions have been updated. Click ",
    [StringResources.pages.terms.newTermsAvailable.second]: "here",
    [StringResources.pages.terms.newTermsAvailable.third]: " to download the new version.",

    [StringResources.pages[PageTypeEnum.PayByLink].title]: "Pay by link",

    [StringResources.pages[PageTypeEnum.ApiKeys].title]: "Api keys",
    [StringResources.pages[PageTypeEnum.ApiKeys].keyName]: "Key name",
    [StringResources.pages[PageTypeEnum.ApiKeys].apiKey]: "Api key",
    [StringResources.pages[PageTypeEnum.ApiKeys].status]: "Status",
    [StringResources.pages[PageTypeEnum.ApiKeys].contracts]: "Agreements",
    [StringResources.pages[PageTypeEnum.ApiKeys].createdTime]: "Date created",
    [StringResources.pages[PageTypeEnum.ApiKeys].userCreated]: "User created",
    [StringResources.pages[PageTypeEnum.ApiKeys].userDisabled]: "User disabled",
    [StringResources.pages[PageTypeEnum.ApiKeys].hasCopyExpired]: "Has copy expired",
    [StringResources.pages[PageTypeEnum.ApiKeys].isSubscribedToAllContracts]: "Is subscribed to all contracts",
    [StringResources.pages[PageTypeEnum.ApiKeys].validTo]: "Valid to",
    [StringResources.pages[PageTypeEnum.ApiKeys].dateDisabled]: "Date disabled",
    [StringResources.pages[PageTypeEnum.ApiKeys].edit]: "Edit",
    [StringResources.pages[PageTypeEnum.ApiKeys].disable]: "Disable",
    [StringResources.pages[PageTypeEnum.ApiKeys].copyToClipboardExpired]:
        "24 hours available to copy to clipboard have expired",
    [StringResources.pages[PageTypeEnum.ApiKeys].copyToClipboard]: "Copy to clipboard",
    [StringResources.pages[PageTypeEnum.ApiKeys].generateNewKey]: "Generate new key",
    [StringResources.pages[PageTypeEnum.ApiKeys].generateNewKeySuccess]: "Successfully generated new key",
    [StringResources.pages[PageTypeEnum.ApiKeys].generateNewKeyError]: "Error while generating new key",
    [StringResources.pages[PageTypeEnum.ApiKeys].editKeySuccess]: "Successfully edited key",
    [StringResources.pages[PageTypeEnum.ApiKeys].editKeyError]: "Error while editing key",
    [StringResources.pages[PageTypeEnum.ApiKeys].disabledKeySuccess]: "Successfully disabled key",
    [StringResources.pages[PageTypeEnum.ApiKeys].disabledKeyError]: "Error while disabling key",
    [StringResources.pages[PageTypeEnum.ApiKeys].generateNewKeyBannerMessage]:
        "”Copy to Clipboard” option will be enabled for 24 hours after the key is created. After this period “Copy to Clipboard” is disabled by the system and new key should be generated instead.",
    [StringResources.pages[PageTypeEnum.ApiKeys].all]: "All",

    [StringResources.export.confirm]: "Export",
    [StringResources.export.title]: "Export",
    [StringResources.export.close]: "Cancel",
    [StringResources.export.exportType]: "Export type",
    [StringResources.export.exportTypeCSV]: "CSV",
    [StringResources.export.exportTypeXLSX]: "XLSX",
    [StringResources.export.exportTypePDF]: "PDF",
    [StringResources.export.exportColumn]: "Select Data",
    [StringResources.export.exportAllColumns]: "All columns available ({{numberOfAvailableColumns}} columns)",
    [StringResources.export.exportVisibleColumns]: "Currently visible columns ({{numberOfVisibleColumns}} columns)",
    [StringResources.export.exportVisibleColumn]: "Currently visible columns ({{numberOfVisibleColumns}} column)",
    [StringResources.export.exportSuccess]: "Download successful",
    [StringResources.export.exportError]: "Download failed",
    [StringResources.export.exportToManyRows]:
        "Export exceeds {{maxItems}} lines. Please adjust the filter and try again.",
    [StringResources.rowSelection.clearAll]: "Clear All",
    [StringResources.rowSelection.selectedRows]: "Selected {{rows}} of {{totalCount}}",

    [StringResources.input.passwordShowCaption]: "Show",
    [StringResources.input.passwordHideCaption]: "Hide",
    [StringResources.input.passwordStrengthBarLabel]: "Password strength",
    [StringResources.input.passwordStrengthBad]: "Bad",
    [StringResources.input.passwordStrengthWeak]: "Weak",
    [StringResources.input.passwordStrengthPassable]: "Passable",
    [StringResources.input.passwordStrengthGood]: "Good",
    [StringResources.input.passwordStrengthVeryGood]: "Very Good",
    [StringResources.input.add]: "Search for",

    [StringResources.login.backActionLabel]: "Back to web",
    [StringResources.login.title]: "Merchant Portal",
    [StringResources.login.formTitle]: "Log in",
    [StringResources.login.formImpersonateTitle]: "Log in (Admin Impersonate Login)",
    [StringResources.login.formEmailInputLabel]: "Email",
    [StringResources.login.formEmailValidationMsg]: "Please enter a valid email address",
    [StringResources.login.formPasswordInputLabel]: "Password",
    [StringResources.login.formButtonCaption]: "Log in",
    [StringResources.login.forgotPasswordLink]: "Forgot your password?",
    [StringResources.login.loginFailed]: "You have entered an invalid username or password",
    [StringResources.forgotPassword.title]: "Reset password?",
    [StringResources.forgotPassword.description]:
        "Please enter the email of your merchant portal account. You will receive an email with a link to choose a new password.",
    [StringResources.forgotPassword.formEmailInputLabel]: "Email",
    [StringResources.forgotPassword.formEmailValidationMsg]: "Please enter a valid email address",
    [StringResources.forgotPassword.formButtonCaption]: "Reset password",
    [StringResources.forgotPassword.backButtonCaption]: "Cancel",
    [StringResources.forgotPassword.formSubmittedTitle]: "Email sent",
    [StringResources.forgotPassword.formSubmittedDescription]:
        "We have sent an email to <strong>{{email}}</strong> with a link to reset your password. Please follow the link to finish resetting your password.",
    [StringResources.forgotPassword.formSubmittedBackButtonCaption]: "Back to log-in",
    [StringResources.resetPassword.title]: "Choose new password",
    [StringResources.resetPassword.formPasswordInputLabel]: "New password",
    [StringResources.resetPassword.formPasswordValidationMsg]: "Password must contain at least 8 characters",
    [StringResources.resetPassword.formConfirmPasswordInputLabel]: "Confirm new password",
    [StringResources.resetPassword.formConfirmPasswordValidationMsg]: "Passwords must match",
    [StringResources.resetPassword.formButtonCaption]: "Submit",
    [StringResources.resetPassword.formSubmittedTitle]: "Password successfully changed",
    [StringResources.resetPassword.formSubmittedDescription]: "You can now log in with your new password.",
    [StringResources.resetPassword.formSubmittedBackButtonCaption]: "Back to log-in",
    [StringResources.confirmAccount.title]: "Welcome to Straumur merchant portal",
    [StringResources.confirmAccount.subTitle]: "Choose a password to finish setting up your account.",
    [StringResources.confirmAccount.formDisplayUsernameInputLabel]: "Email:",
    [StringResources.confirmAccount.formPasswordInputLabel]: "Password",
    [StringResources.confirmAccount.formPasswordValidationMsg]: "Password must contain at least 8 characters",
    [StringResources.confirmAccount.formConfirmPasswordInputLabel]: "Confirm password",
    [StringResources.confirmAccount.formConfirmPasswordValidationMsg]: "Passwords must match",
    [StringResources.confirmAccount.formButtonCaption]: "Submit",
    [StringResources.confirmAccount.formSubmittedTitle]: "Your password was set successfully",
    [StringResources.confirmAccount.formSubmittedDescription]: "You can now log in.",
    [StringResources.confirmAccount.formSubmittedBackButtonCaption]: "Back to log-in",
    [StringResources.confirmAccount.contactServiceDesk]:
        "Having trouble creating your account? Contact <hyperLink>{{straumurEmail}}</hyperLink> or call us at <telLink>{{straumurPhone}}</telLink>.",
    [StringResources.multiselect.selected]: "Selected",
    [StringResources.multiselect.options]: "Options",
    [StringResources.multiselect.selectAll]: "Select All",
    [StringResources.multiselect.clearAll]: "Clear",
    [StringResources.dashboard.totalSales]: "Sales of the month",
    [StringResources.dashboard.totalSalesTooltip]: "Total sales of the current month to date",
    [StringResources.dashboard.salesToday]: "Sales today",
    [StringResources.dashboard.salesTodayTooltip]: "Today's total sales",
    [StringResources.dashboard.upcomingPayment]: "Upcoming payment",
    [StringResources.dashboard.unpaidSettlementsAmount]: "Unpaid balance",
    [StringResources.dashboard.upcomingPaymentTooltip]:
        "The amount to be paid and the next scheduled payment date (including fees)",
    [StringResources.dashboard.unpaidSettlementsAmountToolTip]: "The sum amount of all unpaid settlements",
    [StringResources.dashboard.numberOfSales]: "Number of sales",
    [StringResources.dashboard.numberOfSalesTooltip]: "The number of sales made each day",
    [StringResources.dashboard.grossSales]: "Gross sales",
    [StringResources.dashboard.grossSalesTooltip]: "of monthly target",
    [StringResources.dashboard.online]: "Online",
    [StringResources.dashboard.offline]: "In-store",
    [StringResources.dashboard.pieChartName]: "Online / In-store",
    [StringResources.dashboard.pieChartTooltip]: "Total online sales compared to total sales made in-store",
    [StringResources.dashboard.salesByOutlet]: "Sales by outlet",
    [StringResources.dashboard.salesByOutletTooltip]: "Total sales this month split by locations",
    [StringResources.dashboard.salesByOutletBarTooltip]: "Sale amount",
    [StringResources.dashboard.dailyAverage]: "Daily average",
    [StringResources.dashboard.noDailyAverage]: "No daily average",
    [StringResources.dashboard.ofMonthlyTarget]: "of monthly target",
    [StringResources.dashboard.setSalesTarget]: "Set sales target",
    [StringResources.dashboard.scheduled]: "Scheduled",
    [StringResources.dashboard.day]: "Day",
    [StringResources.dashboard.noData]: "No data to display",
    [StringResources.dashboard.filterByCurrencyPt1]: "Only Agreements in",
    [StringResources.dashboard.filterByCurrencyPt2]: "are shown",
    [StringResources.dashboard.exportGraph]: "Export Graph",
    [StringResources.dashboard.dccCommission]: "DCC Commission",
    [StringResources.dashboard.dccCommissionTooltip]:
        "Total DCC commission earned in the month and the number of DCC transactions",
    [StringResources.dashboard.dccCommissionTransactions]: "transactions",
    [StringResources.dashboard.january]: "January",
    [StringResources.dashboard.february]: "February",
    [StringResources.dashboard.march]: "March",
    [StringResources.dashboard.april]: "April",
    [StringResources.dashboard.may]: "May",
    [StringResources.dashboard.june]: "June",
    [StringResources.dashboard.july]: "July",
    [StringResources.dashboard.august]: "August",
    [StringResources.dashboard.september]: "September",
    [StringResources.dashboard.october]: "October",
    [StringResources.dashboard.november]: "November",
    [StringResources.dashboard.december]: "December",
    [StringResources.dashboard.average]: "Average",
    [StringResources.dashboard.outlet]: "Outlet",
    [StringResources.dashboard.allInCurrency]: "All in",
    [StringResources.dashboard.contracts]: "Contracts",

    [StringResources.versionCheck.text]:
        "We've just released a new update for the app which includes some great new features - so make sure you force update the app to get the latest and greatest by clicking ",
    [StringResources.versionCheck.button]: "here!",

    [StringResources.kyc.backActionLabel]: "Back to web",
    [StringResources.kyc.title]: "KYC Survey",
    [StringResources.kyc.documentTitle]: "Straumur - Áreiðanleikakönnun",
    [StringResources.kyc.formTitle]: "Log in with Auðkenni",
    [StringResources.kyc.formPhoneNumberInputLabel]: "Phone number",
    [StringResources.kyc.formButtonCaption]: "Log in",
    [StringResources.kyc.somethingWentWrong]: "Something went wrong. Please try again later.",
    [StringResources.kycLegalEntity.backActionLabel]: "Back to web",
    [StringResources.kycLegalEntity.title]: "Choose legal entity",
    [StringResources.kycLegalEntity.subTitle]: "You can answer the KYC survey on behalf of these legal entities:",
    [StringResources.kycLegalEntity.empty]: "There are no companies connected to you account",
    [StringResources.kycSurvey.backActionLabel]: "Back to web",
    [StringResources.kycSurvey.title]: "KYC survey",
    [StringResources.kycSurvey.fetchQuestionarieError]: "Something went wrong. Please try again later.",
    [StringResources.kycSurvey.fieldError]: "This question needs to be answered",
    [StringResources.kycSurvey.reviewAnswer]: "Please, review your answer",
    [StringResources.kycSurvey.genericError]: "There is an error with KYC questionnaire. Please review your answers.",
    [StringResources.kycSurvey.changeButton]: "Change",
    [StringResources.kycSurvey.changeText]: "You are answering on behalf of ",
    [StringResources.kycSurvey.formButtonCaption]: "Submit",
    [StringResources.kycSurvey.beneficialOwner.fullName]: "Full name",
    [StringResources.kycSurvey.beneficialOwner.ssn]: "SSN",
    [StringResources.kycSurvey.beneficialOwner.shares]: "Shares",
    [StringResources.kycSurvey.beneficialOwner.phone]: "Phone",
    [StringResources.kycSurvey.beneficialOwner.addPerson]: "Add",
    [StringResources.kycSurvey.legalBinder.fullName]: "Full name",
    [StringResources.kycSurvey.legalBinder.ssn]: "SSN",
    [StringResources.kycSurvey.legalBinder.position]: "Position",
    [StringResources.kycSurvey.legalBinder.phone]: "Phone",
    [StringResources.kycSurvey.legalBinder.addPerson]: "Add",
    [StringResources.kycSurvey.legalBinder.positions.boardMember]: "Board manager",
    [StringResources.kycSurvey.legalBinder.positions.ceo]: "CEO",
    [StringResources.kycSurvey.legalBinder.positions.chairmanOfTheBoard]: "Chairman Of The Board",
    [StringResources.kycSurvey.legalBinder.positions.other]: "Other",
    [StringResources.kycSurvey.legalBinder.positions.powerOfAttorney]: "Power Of Attorney",
    [StringResources.kycSurvey.legalBinder.positions.comanager]: "Co-Manager",
    [StringResources.kycSuccess.backActionLabel]: "Back to web",
    [StringResources.kycSuccess.title]: "Thank you for answering",
    [StringResources.kycSuccess.subTitle]:
        "Your answers have been submitted to Straumur. We’re excited to get you onboard with us.",
    [StringResources.kycSuccess.buttonCaption]: "Back to straumur.is",

    [StringResources.activityTracker.somethingWentWrong]: "Something went wrong. Please log in again.",
    [StringResources.activityTracker.loggedOutInactivtiy]:
        "You have been logged out due to inactivity. Please log in again.",

    [StringResources.networkStatusIndicator.online]: "You are now online!",
    [StringResources.networkStatusIndicator.offline]:
        "You are not connected to the internet. Please check your network settings.",

    [StringResources.pages.terminals.title]: "Terminals",
    [StringResources.pages.terminals.merchantName]: "Merchant",
    [StringResources.pages.terminals.terminalId]: "Terminal ID",
    [StringResources.pages.terminals.ecomTerminalIdentifier]: "Terminal identifier",
    [StringResources.pages.terminals.dbaName]: "DBA name",
    [StringResources.pages.terminals.city]: "City",
    [StringResources.pages.terminals.country]: "Country",
    [StringResources.pages.terminals.mcc]: "MCC",
    [StringResources.pages.terminals.status]: "Status",
    [StringResources.pages.terminals.partnerName]: "Partner name",
    [StringResources.pages.terminals.agreementNumber]: "Agreement number",
    [StringResources.pages.terminals.ecomSolutionType]: "Ecom solution type",
    [StringResources.pages.terminals.generalInfo]: "General Info",
    [StringResources.pages.terminals.terminalFunctionalities]: "Terminal functionalities",
    [StringResources.pages.terminals.dba]: "DBA",
    [StringResources.pages.terminals.expectedStoreTurnoverInIsk]: "Expected store turnover in ISK",
    [StringResources.pages.terminals.create.newAgreement]: "New Agreement",
    [StringResources.pages.terminals.create.newStore]: "New Store",
    [StringResources.pages.terminals.create.requestTerminal]: "Request terminal",
    [StringResources.pages.terminals.create.successMsg]: "Terminal successfully requested",
    [StringResources.pages.terminals.create.title]: "Request terminal",
    [StringResources.pages.terminals.create.store]: "Store",
    [StringResources.pages.terminals.create.typeOfBusiness]: "Type of Business",
    [StringResources.pages.terminals.create.printer]: "Printer",
    [StringResources.pages.terminals.create.noPrinter]: "No printer",
    [StringResources.pages.terminals.create.ecrIntegrated]: "ECR integrated",
    [StringResources.pages.terminals.create.standalone]: "Standalone",
    [StringResources.pages.terminals.create.wifi]: "Wifi",
    [StringResources.pages.terminals.create.fourG]: "4G",
    [StringResources.pages.terminals.create.quantityOfTerminals]: "Quantity of terminals",
    [StringResources.pages.terminals.create.ecrSystem]: "ECR system:",
    [StringResources.pages.terminals.create.name]: "Name",
    [StringResources.pages.terminals.create.streetAndNumber]: "Street and number",
    [StringResources.pages.terminals.create.city]: "City",
    [StringResources.pages.terminals.create.postalCode]: "Postal code",
    [StringResources.pages.terminals.create.country]: "Country",
    [StringResources.pages.terminals.create.averageDeliveryInDays]: "Average delivery in days",
    [StringResources.pages.terminals.create.annualVolumeCardPresent]: "Annual volume Card Present",
    [StringResources.pages.terminals.create.averageMonthlyVolume]: "Average monthly volume",
    [StringResources.pages.terminals.create.maxTransactionAmount]: "Max transaction amount",
    [StringResources.pages.terminals.create.anualVolumeMoto]: "Annual volume MOTO",
    [StringResources.pages.terminals.create.averageTransactionAmount]: "Average transaction amount",
    [StringResources.pages.terminals.create.annualVolumeTotal]: "Annual volume total",
    [StringResources.pages.terminals.create.averageMonthlyNumberOfTransactions]:
        "Average monthly number of transactions",
    [StringResources.pages.terminals.create.newMccLabel]: "Add new...",
    [StringResources.pages.terminals.create.delivery]: "Delivery",
    [StringResources.pages.terminals.create.deliveryType]: "Delivery type",
    [StringResources.pages.terminals.create.terminalDeliveryDate]: "Terminal delivery date",
    [StringResources.pages.terminals.create.comment]: "Comment",

    [StringResources.pages.terminals.error.quantityOfTerminalsMustBeLarger]:
        "Quantity of terminals must be larger then 0",
    [StringResources.pages.terminals.error.quantityOfTerminalsRequired]: "Quantity of terminals is required",
    [StringResources.pages.terminals.error.ecrSystemRequired]: "ECR system is required if ECR integrated is selected",
    [StringResources.pages.terminals.error.agreementNumberRequired]: "Agreement number is required",
    [StringResources.pages.terminals.error.storeIsRequired]: "Store is required",
    [StringResources.pages.terminals.error.typeOfBusinessIsRequired]: "Type of business is required",
    [StringResources.pages.terminals.error.nameIsRequired]: "Name is required",
    [StringResources.pages.terminals.error.streetAndNumberIsRequired]: "Street and number is required",
    [StringResources.pages.terminals.error.cityIsRequired]: "City is required",
    [StringResources.pages.terminals.error.postalCodeIsRequired]: "Postal code is required",
    [StringResources.pages.terminals.error.countryIsRequired]: "Country is required",
    [StringResources.pages.terminals.error.averageDeliveryInDaysIsRequired]: "Average delivery in days is required",
    [StringResources.pages.terminals.error.annualVolumeCardPresentIsRequired]: "Annual volume Card Present is required",
    [StringResources.pages.terminals.error.averageMonthlyVolumeIsRequired]: "Average montly volume is required",
    [StringResources.pages.terminals.error.maxTransactionAmountIsRequired]: "Max transaction amount is required",
    [StringResources.pages.terminals.error.annualVolumeMotoIsRequired]: "Annual volume MOTO is required",
    [StringResources.pages.terminals.error.averageTransactionAmountIsRequired]:
        "Average transaction amount is required",
    [StringResources.pages.terminals.error.annualVolumeTotalIsRequired]: "Annual volume total is required",
    [StringResources.pages.terminals.error.averageMonthlyNumberOfTransactionsIsRequired]:
        "Average monthly number of transactions is required",
    [StringResources.pages.terminals.error.fetchError]: "Error occurred while fetching data",
    [StringResources.pages.terminals.error.submitError]: "Error occurred while submiting data",
    [StringResources.reports[ReportType.Agreements].title]: "Agreements",
    [StringResources.reports[ReportType.Authorizations].title]: "Authorizations",
    [StringResources.reports[ReportType.Batches].title]: "Batches",
    [StringResources.reports[ReportType.DailyBalance].title]: "Daily Balance",
    [StringResources.reports[ReportType.ElectronicDocuments].title]: "Electronic Documents",
    [StringResources.reports[ReportType.Payments].title]: "Payments",
    [StringResources.reports[ReportType.Settlements].title]: "Settlements",
    [StringResources.reports[ReportType.Transactions].title]: "Transactions",
    [StringResources.reports[ReportType.Terminals].title]: "Terminals",
    [StringResources.reports[ReportType.Employees].title]: "Employees",
    [StringResources.reports[ReportType.DisputeNew].title]: "Disputes",
    [StringResources.reports[ReportType.Overview].title]: "Overview",
    [StringResources.reports[ReportType.Disputes].title]: "Disputes",

    [StringResources.reports[ReportType.PayByLink].title]: "Pay by link",
    [StringResources.reports[ReportType.PayByLink].createPaymentLink]: "Create payment link",
    [StringResources.reports[ReportType.PayByLink].paymentLink]: "Payment link",
    [StringResources.reports[ReportType.PayByLink].amount]: "Amount",
    [StringResources.reports[ReportType.PayByLink].amountFrom]: "Amount from",
    [StringResources.reports[ReportType.PayByLink].amountTo]: "Amount to",
    [StringResources.reports[ReportType.PayByLink].description]: "Description",
    [StringResources.reports[ReportType.PayByLink].dateCreated]: "Date created",
    [StringResources.reports[ReportType.PayByLink].dateCreatedFrom]: "Date created from",
    [StringResources.reports[ReportType.PayByLink].dateCreatedTo]: "Date created to",
    [StringResources.reports[ReportType.PayByLink].currencyId]: "Currency",
    [StringResources.reports[ReportType.PayByLink].status]: "Status",
    [StringResources.reports[ReportType.PayByLink].visits]: "Visits",
    [StringResources.reports[ReportType.PayByLink].sales]: "Sales",
    [StringResources.reports[ReportType.PayByLink].createdBy]: "Created by",
    [StringResources.reports[ReportType.PayByLink].expires]: "Expires",
    [StringResources.reports[ReportType.PayByLink].copyToClipboard]: "Copy to clipboard",
    [StringResources.reports[ReportType.PayByLink].update]: "Update",
    [StringResources.reports[ReportType.PayByLink].copy]: "Copy",
    [StringResources.reports[ReportType.PayByLink].disable]: "Disable",
    [StringResources.reports[ReportType.PayByLink].disableLinkErrorMessage]:
        "Error while trying to disable payment link",
    [StringResources.reports[ReportType.PayByLink].disableLinkSuccessMessage]: "Successfully disabled payment link",
    [StringResources.reports[ReportType.PayByLink].disableLinkSuccessMessage]: "Successfully disabled payment link",
    [StringResources.reports[ReportType.PayByLink].updateLinkErrorMessage]: "Error while trying to update payment link",
    [StringResources.reports[ReportType.PayByLink].updateLinkSuccessMessage]: "Successfully updated payment link",
    [StringResources.reports[ReportType.PayByLink].actions]: "Action(s)",

    [StringResources.reports[ReportType.PayByLinkTransactions].title]: "Sales",
    [StringResources.reports[ReportType.PayByLinkTransactions].date]: "Date",
    [StringResources.reports[ReportType.PayByLinkTransactions].amount]: "Amount",
    [StringResources.reports[ReportType.PayByLinkTransactions].paymentLink]: "Payment link",
    [StringResources.reports[ReportType.PayByLinkTransactions].refundAmount]: "Refund amount",
    [StringResources.reports[ReportType.PayByLinkTransactions].currency]: "Currency",
    [StringResources.reports[ReportType.PayByLinkTransactions].referenceNumber]: "Reference number",
    [StringResources.reports[ReportType.PayByLinkTransactions].transactionNumber]: "Transaction number",
    [StringResources.reports[ReportType.PayByLinkTransactions].productDescription]: "Product description",
    [StringResources.reports[ReportType.PayByLinkTransactions].cardNumber]: "Card number",
    [StringResources.reports[ReportType.PayByLinkTransactions].cardType]: "Card type",
    [StringResources.reports[ReportType.PayByLinkTransactions].agreementNumber]: "Agreement number",
    [StringResources.reports[ReportType.PayByLinkTransactions].shopperName]: "Purchaser name",
    [StringResources.reports[ReportType.PayByLinkTransactions].shopperEmail]: "Purchaser email",
    [StringResources.reports[ReportType.PayByLinkTransactions].shopperSSN]: "Purchaser SSN",
    [StringResources.reports[ReportType.PayByLinkTransactions].postalCode]: "Postal code",
    [StringResources.reports[ReportType.PayByLinkTransactions].address]: "Address",
    [StringResources.reports[ReportType.PayByLinkTransactions].city]: "City",
    [StringResources.reports[ReportType.PayByLinkTransactions].country]: "Country",
    [StringResources.reports[ReportType.PayByLinkTransactions].phoneNumber]: "Phone number",
    [StringResources.reports[ReportType.PayByLinkTransactions].comments]: "Comments",
    [StringResources.reports[ReportType.PayByLinkTransactions].nameOfShopper]: "Name of purchaser",
    [StringResources.reports[ReportType.PayByLinkTransactions].ssn]: "SSN",
    [StringResources.reports[ReportType.PayByLinkTransactions].amountFrom]: "Amount from",
    [StringResources.reports[ReportType.PayByLinkTransactions].amountTo]: "Amount to",
    [StringResources.reports[ReportType.PayByLinkTransactions].dateCreated]: "Date created",
    [StringResources.reports[ReportType.PayByLinkTransactions].copyToClipboard]: "Copy to clipboard",
    [StringResources.reports[ReportType.PayByLinkTransactions].viewAuthorization]: "View authorization",
    [StringResources.reports[ReportType.PayByLinkTransactions].viewTransaction]: "View transaction",

    [StringResources.reports[ReportType.PayByLinkTransactions].title]: "Sales",

    [StringResources.enums.terminalRequestDeliveryType[TerminalRequestDeliveryType.Pickup]]: "Pickup",
    [StringResources.enums.terminalRequestDeliveryType[TerminalRequestDeliveryType.Send]]: "Send",
    [StringResources.enums.language[SupportedLanguageEnum.English]]: "English",
    [StringResources.enums.language[SupportedLanguageEnum.Icelandic]]: "Icelandic",

    [StringResources.pages.ecomThemes.title]: "Themes",
    [StringResources.pages.ecomThemes.create.addNewTheme]: "Add new theme",
    [StringResources.pages.ecomThemes.create.successMsg]: "Theme successfully created",
    [StringResources.pages.ecomThemes.create.title]: "Add new e-Com theme",
    [StringResources.pages.ecomThemes.create.themeKey]: "Theme Key",
    [StringResources.pages.ecomThemes.create.name]: "Name",
    [StringResources.pages.ecomThemes.create.description]: "Description",
    [StringResources.pages.ecomThemes.create.colorHex]: "Brand color #hex",
    [StringResources.pages.ecomThemes.create.logo]: "Logo",
    [StringResources.pages.ecomThemes.create.setAsDefaultTheme]: "Set as default theme",
    [StringResources.pages.ecomThemes.create.generalThemeInfo]: "General theme info",
    [StringResources.pages.ecomThemes.create.design]: "Design",
    [StringResources.pages.ecomThemes.create.upload]: "Upload",
    [StringResources.pages.ecomThemes.create.create]: "Create",
    [StringResources.pages.ecomThemes.create.default]: "Default",
    [StringResources.pages.ecomThemes.create.remove]: "Remove",
    [StringResources.pages.ecomThemes.create.maxWidth]: "Max width",
    [StringResources.pages.ecomThemes.create.maxHeight]: "Max height",
    [StringResources.pages.ecomThemes.create.maxFileSize]: "Max file size",
    [StringResources.pages.ecomThemes.create.error.invalidType]: "Only JPG, PNG, or SVG files are allowed.",
    [StringResources.pages.ecomThemes.create.error.wrongDimensions]:
        "Image dimensions must not exceed 280px width and 64px height.",
    [StringResources.pages.ecomThemes.create.error.wrongSize]: "File size must be less than 50KB.",
    [StringResources.pages.ecomThemes.edit.editTheme]: "Edit e-Com theme",
    [StringResources.pages.ecomThemes.edit.save]: "Save",
    [StringResources.pages.ecomThemes.edit.copyToClipboard]: "Copied to clipboard",
    [StringResources.pages.ecomThemes.edit.successMsg]: "Successfully edited theme",
    [StringResources.pages.ecomThemes.delete.deleteTitle]: "Delete theme",
    [StringResources.pages.ecomThemes.delete.deleteMessage]: "Are you sure you want to delete theme?",
    [StringResources.pages.ecomThemes.delete.successMsg]: "Successfully deleted theme",
    [StringResources.pages.ecomThemes.error.nameIsRequired]: "Name is required",
    [StringResources.pages.ecomThemes.error.colorIsRequired]: "Brand color is required",

    [StringResources.pages[PageTypeEnum.Webhooks].bannerTitle]: "Important",
    [StringResources.pages[PageTypeEnum.Webhooks].bannerFirstPharagraph]:
        "You will receive webhook events only for terminals received after 1st of October 2024. If you would like the latest terminals, please contact straumur@straumur.is",
    [StringResources.pages[PageTypeEnum.Webhooks].bannerSecondPharagraph]:
        "Accept webhooks by responding with a HTTP 200 status code",
    [StringResources.pages[PageTypeEnum.Webhooks].bannerThirdPharagraph]:
        "When you create a new webhook, your endpoint that accepts webhooks must respond to webhooks with a HTTP 200 status code.",
    [StringResources.pages[PageTypeEnum.Webhooks].title]: "Webhooks",
    [StringResources.pages[PageTypeEnum.Webhooks].webhookName]: "Name",
    [StringResources.pages[PageTypeEnum.Webhooks].webhookUrl]: "URL",
    [StringResources.pages[PageTypeEnum.Webhooks].webhookApiKey]: "Webhook API key",
    [StringResources.pages[PageTypeEnum.Webhooks].hmac]: "HMAC",
    [StringResources.pages[PageTypeEnum.Webhooks].status]: "Status",
    [StringResources.pages[PageTypeEnum.Webhooks].contractNumbers]: "Agreements",
    [StringResources.pages[PageTypeEnum.Webhooks].createdDate]: "Date created",
    [StringResources.pages[PageTypeEnum.Webhooks].userCreated]: "User created",
    [StringResources.pages[PageTypeEnum.Webhooks].userDisabled]: "User disabled",
    [StringResources.pages[PageTypeEnum.Webhooks].disabledDate]: "Date disabled",
    [StringResources.pages[PageTypeEnum.Webhooks].isSubscribedToAllContracts]: "Is subscribed to all contracts",
    [StringResources.pages[PageTypeEnum.Webhooks].copyToClipboardExpired]:
        "24 hours available to copy to clipboard have expired",
    [StringResources.pages[PageTypeEnum.Webhooks].copyToClipboard]: "Copy to clipboard",
    [StringResources.pages[PageTypeEnum.Webhooks].edit]: "Edit",
    [StringResources.pages[PageTypeEnum.Webhooks].disable]: "Disable",
    [StringResources.pages[PageTypeEnum.Webhooks].createWebhook]: "Register new webhook",
    [StringResources.pages[PageTypeEnum.Webhooks].createWebhookSuccess]: "Successfully generated new webhook",
    [StringResources.pages[PageTypeEnum.Webhooks].createWebhookError]: "Error while generating new webhook",
    [StringResources.pages[PageTypeEnum.Webhooks].editWebhookSuccess]: "Successfully edited webhook",
    [StringResources.pages[PageTypeEnum.Webhooks].editWebhookError]: "Error while editing webhook",
    [StringResources.pages[PageTypeEnum.Webhooks].disabledWebhoookSuccess]: "Successfully disabled webhook",
    [StringResources.pages[PageTypeEnum.Webhooks].disabledWebhookError]: "Error while disabling webhook",
    [StringResources.pages[PageTypeEnum.Webhooks].modalFirstPharagraph]:
        "Please ensure you’ve entered a complete absolute URL. It should start with 'https://'",
    [StringResources.pages[PageTypeEnum.Webhooks].modalSecondPharagraph]:
        "New webhook API key will have option to Copy Key to Clipboard for next 24 hours. After this period, Copy Key to Clipboard is disabled by the system. ",
    [StringResources.pages[PageTypeEnum.Webhooks].errors.webhookNameRequired]: "Webhook name is required",
    [StringResources.pages[PageTypeEnum.Webhooks].errors.webhookUrlRequired]: "Webhook URL is required",
    [StringResources.pages[PageTypeEnum.Webhooks].errors.incorrectUrl]:
        "URL format incorrect - it must be absolute https:// URL",
};
