import { IconSVGProps } from "./types";

const WebhooksIcon = ({ height = 20, width = 20, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.99957 6.94466C10.4416 6.94466 10.8655 6.76907 11.1781 6.45651C11.4906 6.14395 11.6662 5.72002 11.6662 5.27799C11.6662 4.83597 11.4906 4.41204 11.1781 4.09948C10.8655 3.78692 10.4416 3.61133 9.99957 3.61133C9.55754 3.61133 9.13362 3.78692 8.82105 4.09948C8.50849 4.41204 8.3329 4.83597 8.3329 5.27799C8.3329 5.72002 8.50849 6.14395 8.82105 6.45651C9.13362 6.76907 9.55754 6.94466 9.99957 6.94466ZM6.11068 14.1669C6.11068 13.7249 5.93508 13.3009 5.62252 12.9884C5.30996 12.6758 4.88604 12.5002 4.44401 12.5002C4.00198 12.5002 3.57806 12.6758 3.2655 12.9884C2.95294 13.3009 2.77734 13.7249 2.77734 14.1669C2.77734 14.6089 2.95294 15.0328 3.2655 15.3454C3.57806 15.658 4.00198 15.8336 4.44401 15.8336C4.88604 15.8336 5.30996 15.658 5.62252 15.3454C5.93508 15.0328 6.11068 14.6089 6.11068 14.1669ZM17.2218 14.1669C17.2218 13.7249 17.0462 13.3009 16.7336 12.9884C16.4211 12.6758 15.9971 12.5002 15.5551 12.5002C15.1131 12.5002 14.6892 12.6758 14.3766 12.9884C14.064 13.3009 13.8885 13.7249 13.8885 14.1669C13.8885 14.6089 14.064 15.0328 14.3766 15.3454C14.6892 15.658 15.1131 15.8336 15.5551 15.8336C15.9971 15.8336 16.4211 15.658 16.7336 15.3454C17.0462 15.0328 17.2218 14.6089 17.2218 14.1669Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M0 14.1673C0 11.959 1.60764 10.1291 3.71528 9.7819L3.98611 11.4277C2.67014 11.643 1.66667 12.7888 1.66667 14.1673C1.66667 15.702 2.90972 16.9451 4.44444 16.9451C5.97917 16.9451 7.22222 15.702 7.22222 14.1673V13.334H8.05556H14.1111C14.0417 13.4555 13.9861 13.5875 13.9479 13.7298C13.9306 13.7993 13.9132 13.8722 13.9063 13.9451C13.9028 13.9833 13.8993 14.018 13.8958 14.0562C13.8924 14.0944 13.8924 14.1326 13.8924 14.1708C13.8924 14.4729 13.9722 14.7576 14.1146 15.0041H8.8125C8.42014 17.0562 6.61458 18.6118 4.44444 18.6118C1.98958 18.6118 0 16.6222 0 14.1673ZM4.50347 12.5007L6.97917 8.53884C6.10417 7.72635 5.55556 6.56662 5.55556 5.27843C5.55556 2.82357 7.54514 0.833984 10 0.833984C12.4549 0.833984 14.4444 2.82357 14.4444 5.27843C14.4444 5.86871 14.3299 6.43121 14.1215 6.94857L12.5764 6.32357C12.7049 6.00412 12.7778 5.64996 12.7778 5.2819C12.7778 3.74718 11.5347 2.50412 10 2.50412C8.46528 2.50412 7.22222 3.74718 7.22222 5.2819C7.22222 6.27496 7.74306 7.14648 8.52778 7.63954L9.23264 8.08051L8.79167 8.78537L5.91667 13.3861C5.64583 12.8757 5.11458 12.525 4.50347 12.5041V12.5007ZM10.059 6.9451C10.6736 6.92426 11.2014 6.57357 11.4722 6.06315L13.9479 10.025C14.4479 9.83051 14.9896 9.72635 15.5556 9.72635C18.0104 9.72635 20 11.7159 20 14.1708C20 16.6257 18.0104 18.6152 15.5556 18.6152C14.3229 18.6152 13.2049 18.1118 12.4028 17.3027L13.5868 16.1291C14.0903 16.6326 14.7847 16.9451 15.5556 16.9451C17.0903 16.9451 18.3333 15.702 18.3333 14.1673C18.3333 12.6326 17.0903 11.3895 15.5556 11.3895C15.0139 11.3895 14.5104 11.5423 14.0833 11.8097L13.375 12.2541L12.934 11.5458L10.059 6.9451Z"
            fill="#002649"
        />
    </svg>
);

export default WebhooksIcon;
