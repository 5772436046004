import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import BaseDatepicker from "components/baseDatepicker/baseDatepicker";
import BaseInput from "components/baseInput/baseInput";
import BaseRadio from "components/baseRadio/baseRadio";
import BaseSelect, { Option } from "components/baseSelect/baseSelect";
import BaseToggleSwitch from "components/baseToggleSwitch/baseToggleSwitch";
import CreatePaymentLinkModalProductRow from "containers/modalContainers/createPaymentLinkModal/createPaymentLinkModalProductRow";
import Decimal from "decimal.js";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { RootState, useAppDispatch } from "redux/store";
import { ModalTypeEnum, ShopperInfoRequirementType, SupportedLanguageEnum } from "utils/enums";
import { Currency, formatter } from "utils/formatter";
import { createUUID, getLanguages } from "utils/helperFunctions";
import { StringResources } from "utils/language/languageResource";
import * as Yup from "yup";
import "./createPaymentLinkModal.scss";
import {
    CreatePaymentLinkModalProps,
    CreatePaymentLinkSalesType,
    IBuyerInfoData,
    ICreatePaymentLinkRequestForm,
    IEcomResponseData,
    IExpirationDateOptions,
    IPaymentLinkShopperInfo,
    IPaymentLinkThemeResponseData,
    IProductItemsData,
    IProductRow,
    shopperInfoDataInitialValues,
} from "./createPaymentLinkModel";
import OpacityLoader from "components/baseLoader/opacityLoader";

const languages = getLanguages();

const CreatePamentLinkModal = (props: CreatePaymentLinkModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [paymentPageOptions, setPaymentPageOptions] = useState<Array<Option>>([]);
    const [expirationDateOptions, setExpirationDateOptions] = useState<Array<Option>>([]);
    const [paymentPage, setPaymentPage] = useState<Array<IEcomResponseData>>([]);
    const [paymentLinkThemeOptions, setPaymentLinkThemeOptions] = useState<Array<Option>>([]);
    const permissionTypes = useSelector((state: RootState) => state.global.filterTypes.employeePermissionTypes);
    const [currencyId, setCurrencyId] = useState("ISK" as Currency);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [prodValues, setProdValues] = useState<Array<IProductRow>>([
        {
            id: createUUID(),
            description: undefined,
            quantity: 1,
            amountWithoutDiscount: 0,
            unitPrice: 0,
            amount: 0,
            currencyId: currencyId,
        },
    ]);

    const formValidation = useFormik({
        enableReinitialize: false,
        validateOnChange: true,
        validateOnMount: false,
        validateOnBlur: true,
        initialValues: {
            paymentPage: undefined,
            paymentLinkTheme: undefined,
            payByLinkDescription: "",
            purchaseReturnUrl: "",
            language: SupportedLanguageEnum.Icelandic,
            dateTo: undefined,
            salesType: CreatePaymentLinkSalesType.UnlimitedSales,
            exactSalesCount: undefined,
            hasExpirationDate: undefined,
            totalAmount: 0,
            shopperInfoData: [...shopperInfoDataInitialValues.map(x => ({ ...x, label: t(x.label) }))],
            productValues: [
                {
                    id: createUUID(),
                    description: undefined,
                    quantity: 1,
                    amountWithoutDiscount: 0,
                    unitPrice: 0,
                    amount: 0,
                    currencyId: currencyId,
                },
            ],
            permissions: permissionTypes.map(x => x.value),
        },
        validationSchema: Yup.object({
            paymentPage: Yup.string().required(t(StringResources.modal.createPaymentLink.paymentPageRequired)),
            language: Yup.number().required(t(StringResources.modal.createPaymentLink.languageRequired)),
            salesType: Yup.number().required(t(StringResources.modal.createPaymentLink.salesTypeRequired)),
            exactSalesCount: Yup.number().when("salesType", {
                is: CreatePaymentLinkSalesType.ExactSales,
                then: Yup.number().required(t(StringResources.modal.createPaymentLink.exactSalesCountRequired)),
                otherwise: Yup.number().notRequired(),
            }),
            hasExpirationDate: Yup.boolean().required(
                t(StringResources.modal.createPaymentLink.hasExpirationDateRequired)
            ),
            dateTo: Yup.date().when("hasExpirationDate", {
                is: true,
                then: Yup.date().required(t(StringResources.modal.createPaymentLink.validToRequired)),
                otherwise: Yup.date().notRequired(),
            }),
            productValues: Yup.array().of(
                Yup.object().shape({
                    description: Yup.string().required(
                        `${t(StringResources.modal.createPaymentLink.descriptionRequired)}`
                    ),
                    quantity: Yup.number()
                        .min(1, `${t(StringResources.modal.createPaymentLink.quantityMustBeAtLeastOne)}`)
                        .required(`${t(StringResources.modal.createPaymentLink.quantityIsRequired)}`),
                    unitPrice: Yup.number()
                        .test(
                            "is-greater-than-zero",
                            t(StringResources.modal.createPaymentLink.unitPriceMustBeHigherThanZero),
                            function (value) {
                                if (value! > 0) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        )
                        .required(t(StringResources.modal.createPaymentLink.unitPriceMustBeHigherThanZero)),
                    amountWithoutDiscount: Yup.number()
                        .test("is-greater-than-zero", function (value) {
                            if (value! > 0) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .required(),
                    amount: Yup.number()
                        .test(
                            "is-lower-or-equal",
                            `${t(StringResources.modal.createPaymentLink.amountGreaterThanAmountWithoutDiscount)}`,
                            function (value) {
                                const amountWithoutDiscount = this.parent.amountWithoutDiscount;
                                if (value! <= amountWithoutDiscount) {
                                    return true;
                                } else return false;
                            }
                        )
                        .required(),
                })
            ),
            totalAmount: Yup.number()
                .positive(t(StringResources.modal.createPaymentLink.totalAmountMustBeHigherThanZero))
                .required(),
            purchaseReturnUrl: Yup.string().matches(
                /^(https?:\/\/)([a-z0-9-]+(\.[a-z0-9-]+)*)\/?([a-zA-Z0-9#-_]+\/?)*(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                t(StringResources.modal.createPaymentLink.incorrectUrl)
            ),
        }),
        onSubmit: async (value: ICreatePaymentLinkRequestForm) => {
            const totalAmount = prodValues
                .filter(x => x.amount != 0 && x.amount != undefined)
                .map(x => x.amount!)
                .reduce((a, b) => a.plus(b), new Decimal(0))
                .toNumber();

            // don't send if amount is 0 for the last
            let prodValuesForRequest = prodValues.map(item => ({
                ...item,
                amount: item.amount,
                unitPrice: item.unitPrice,
                amountWithoutDiscount: item.amountWithoutDiscount,
            }));

            // don't send ones that are hidden because we don't save them to db
            let shopperInfoDataForRequest = formValidation.values.shopperInfoData
                .filter(x => x.shopperInfoRequirementType != ShopperInfoRequirementType.Hidden)
                .map(({ label, ...item }) => item);

            if (!props.data.isUpdate || props.data.isCopy) {
                // remove ids from request
                prodValuesForRequest = prodValuesForRequest.map(({ productDetailsId, ...item }) => item);
                shopperInfoDataForRequest = shopperInfoDataForRequest.map(({ id, ...item }) => item);
                const selectedPaymentPage = paymentPage.find(x => x.ecomSolutionId == value.paymentPage);

                try {
                    await executeAxiosRequestWithRefresh({
                        url: `/api/paymentLink/create-payment-link`,
                        method: "POST",
                        data: {
                            currencyId: selectedPaymentPage?.currencyId,
                            contractId: selectedPaymentPage?.contractId,
                            ecomSolutionId: value.paymentPage,
                            paymentLinkThemeId: value.paymentLinkTheme,
                            payByLinkDescription: value.payByLinkDescription,
                            languageType: value.language,
                            purchaseReturnUrl: value.purchaseReturnUrl,
                            validTo: value.dateTo,
                            shopperInfos: shopperInfoDataForRequest,
                            exactSalesCount: value.exactSalesCount,
                            productDetails: prodValuesForRequest,
                            totalAmount: totalAmount,
                            permissions: value.permissions,
                        },
                    });
                    dispatch(confirmModal({ modalType: ModalTypeEnum.CreatePaymentLink }));
                    if (props.data.isUpdate) {
                        toast.success(`${t(StringResources.modal.createPaymentLink.successUpdateMessage)}`);
                    } else {
                        toast.success(`${t(StringResources.modal.createPaymentLink.successMessage)}`);
                    }
                } catch (error: any) {
                    toast.error(`${t(StringResources.modal.createPaymentLink.errorMessage)}`);
                }
                return;
            }

            try {
                const selectedPaymentPage = paymentPage.find(x => x.ecomSolutionId == value.paymentPage);

                await executeAxiosRequestWithRefresh({
                    url: `/api/paymentLink/update-payment-link`,
                    method: "POST",
                    data: {
                        paymentLinkId: props.data.paymentLinkId!,
                        terminalId: value.paymentPage,
                        paymentLinkThemeId: value.paymentLinkTheme,
                        payByLinkDescription: value.payByLinkDescription,
                        languageType: value.language,
                        currencyId: selectedPaymentPage?.currencyId,
                        purchaseReturnUrl: value.purchaseReturnUrl,
                        validTo: value.dateTo,
                        shopperInfos: shopperInfoDataForRequest,
                        exactSalesCount: value.exactSalesCount,
                        productDetails: prodValuesForRequest,
                        totalAmount: totalAmount,
                        permissions: value.permissions,
                    },
                });
                dispatch(confirmModal({ modalType: ModalTypeEnum.CreatePaymentLink }));
                toast.success(`${t(StringResources.modal.createPaymentLink.successUpdateMessage)}`);
            } catch (error) {
                toast.error(`${t(StringResources.modal.createPaymentLink.errorUpdateMessage)}`);
            }
        },
    });

    useEffect(() => {
        const fetchSelectOptions = async () => {
            try {
                const response = await executeAxiosRequestWithRefresh({
                    url: `/api/paymentLink/get-pay-by-link-request-data`,
                    method: "GET",
                });

                if (response && response.data) {
                    const ecomResponseData = response.data.ecomResponseData;
                    const paymentLinkThemeResponseData = response.data.paymentLinkThemeResponseData;

                    await setPaymentPage(ecomResponseData);

                    const paymentPageOptions: Array<Option> = ecomResponseData.map((x: IEcomResponseData) => {
                        return {
                            label: `${x.contractNumber} - ${x.dbaName} - ${x.currencyId} - ${x.tid}`,
                            value: x.ecomSolutionId,
                            selected: false,
                        };
                    });

                    await setPaymentPageOptions(paymentPageOptions);

                    const paymentLinkThemeOptions: Array<Option> = paymentLinkThemeResponseData.map(
                        (x: IPaymentLinkThemeResponseData) => {
                            return {
                                label: `${x.themeId} - ${x.themeName} ${
                                    x.themeDescription ? "- " + x.themeDescription : ""
                                }`,
                                value: x.themeId,
                                selected: false,
                            };
                        }
                    );

                    await setPaymentLinkThemeOptions(paymentLinkThemeOptions);

                    await setIsLoading(false);
                }
            } catch (error) {
                toast.error(`${t(StringResources.modal.createPaymentLink.fetchPaymentPageError)}`);
            }
        };

        const fetchPaymentLinkDetailsData = async () => {
            try {
                const paymentLinkId = props.data.paymentLinkId;
                const response = await executeAxiosRequestWithRefresh({
                    url: `/api/paymentLink/details`,
                    method: "GET",
                    params: { paymentLinkId },
                });

                if (response && response.data) {
                    const productResponseData = response.data.productItemsData;
                    const shopperInfoResponseData = response.data.shopperInfoData;

                    const productItems: Array<IProductRow> = productResponseData.map((x: IProductItemsData) => {
                        return {
                            id: createUUID(),
                            productDetailsId: x.id,
                            description: x.description,
                            quantity: x.quantity,
                            amountWithoutDiscount: new Decimal(x.amountWithoutDiscount).toNumber(),
                            amount: new Decimal(x.amount).toNumber(),
                            unitPrice: new Decimal(x.unitPrice).toNumber(),
                            currencyId: response.data.currencyId,
                        };
                    });

                    await setProdValues(productItems);

                    const shopperInfoData: Array<IPaymentLinkShopperInfo> = shopperInfoResponseData.map(
                        (x: IBuyerInfoData) => {
                            return {
                                id: x.id,
                                shopperInfoRequirementType: x.shopperInfoRequirementType,
                                field: x.shopperInfoFieldEnum,
                            };
                        }
                    );

                    const updatedShopperInfoData = shopperInfoDataInitialValues.map(item1 => {
                        const matchingItem = shopperInfoData.find(item2 => item2.field === item1.field);
                        return matchingItem
                            ? {
                                  ...item1,
                                  shopperInfoRequirementType: matchingItem.shopperInfoRequirementType,
                                  label: t(item1.label),
                              }
                            : {
                                  ...item1,
                                  label: t(item1.label),
                                  shopperInfoRequirementType: item1.shopperInfoRequirementType,
                              };
                    });

                    const totalAmount = await productItems
                        .filter(x => x.amount != undefined)
                        .map(x => new Decimal(Number(x.amount!)))
                        .reduce((a, b) => a.plus(b), new Decimal(0))
                        .toNumber();

                    let dateTo = undefined;
                    let hasExpirationDate = undefined;

                    if (props.data.isCopy) {
                        dateTo = undefined;
                    } else {
                        if (response.data.validTo !== undefined && response.data.validTo !== null) {
                            hasExpirationDate = true;
                            dateTo = response.data.validTo;
                        } else {
                            hasExpirationDate = false;
                            dateTo = undefined;
                        }
                    }

                    let salesType = CreatePaymentLinkSalesType.UnlimitedSales;
                    let exactSalesCount = undefined;

                    if (response.data.exactSalesCount === undefined || response.data.exactSalesCount === null) {
                        salesType = CreatePaymentLinkSalesType.UnlimitedSales;
                    } else if (response.data.exactSalesCount == 1) {
                        salesType = CreatePaymentLinkSalesType.SingleSales;
                        exactSalesCount = response.data.exactSalesCount;
                    } else {
                        salesType = CreatePaymentLinkSalesType.ExactSales;
                        exactSalesCount = response.data.exactSalesCount;
                    }

                    await setCurrencyId(response.data.currencyId);

                    await formValidation.setValues({
                        paymentPage: response.data.terminalId,
                        paymentLinkTheme: response.data.paymentLinkThemeId,
                        payByLinkDescription: response.data.paymentLinkDescription ?? "",
                        purchaseReturnUrl: response.data.purchaseReturnUrl ?? "",
                        language: response.data.languageType,
                        dateTo: dateTo,
                        salesType: salesType,
                        exactSalesCount: exactSalesCount,
                        hasExpirationDate: hasExpirationDate,
                        totalAmount: totalAmount,
                        shopperInfoData: updatedShopperInfoData,
                        productValues: productItems,
                        permissions: permissionTypes.map(x => x.value),
                    });

                    await setIsLoading(false);
                }
            } catch (error) {
                toast.error(`${t(StringResources.modal.createPaymentLink.fetchPaymentLinkDetailsError)}`);
            }
        };

        const fetchOptions = async () => {
            const expirationDateValues: Array<IExpirationDateOptions> = [
                {
                    description: `${t(StringResources.modal.createPaymentLink.doesNotExpire)}`,
                    hasExpirationDate: false,
                },
                {
                    description: `${t(StringResources.modal.createPaymentLink.hasExpirationDate)}`,
                    hasExpirationDate: true,
                },
            ];

            const expirationDateOptions: Array<Option> = expirationDateValues.map((x: IExpirationDateOptions) => {
                return {
                    label: `${x.description}`,
                    value: x.hasExpirationDate,
                    selected: false,
                };
            });

            await setExpirationDateOptions(expirationDateOptions);
        };

        // todo change after be is merged
        fetchOptions();
        fetchSelectOptions();

        if (props.data.isUpdate || props.data.isCopy) {
            setIsLoading(true);
            fetchPaymentLinkDetailsData();

            formValidation.setErrors({});
        }
    }, []);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.CreatePaymentLink }));
    };

    const onOkClick = async () => {
        await formValidation.handleSubmit();
    };

    const onPaymentPageChange = async (selectedItem: Option | undefined) => {
        if (selectedItem && selectedItem.value !== 0) {
            await formValidation.setFieldValue("paymentPage", selectedItem.value);
            let currency = paymentPage.find(x => x.ecomSolutionId == selectedItem.value)!.currencyId;

            if (currency === currencyId) {
                return;
            }

            if (currency == "" || currency === undefined) {
                currency = "ISK";
            }

            setCurrencyId(currency as Currency);

            await setProdValues([
                {
                    id: createUUID(),
                    description: undefined,
                    quantity: 1,
                    amountWithoutDiscount: 0,
                    unitPrice: 0,
                    amount: 0,
                    currencyId: currency as Currency,
                },
            ]);

            formValidation.setFieldValue("productValues", prodValues);
        } else {
            await formValidation.setFieldValue("paymentPage", undefined);
        }
    };

    const onOptionValueChange = async (
        selectedItem: Option | undefined,
        fieldName: "hasExpirationDate" | "paymentLinkTheme" | "language"
    ) => {
        if (selectedItem && selectedItem.value !== 0) {
            await formValidation.setFieldValue(fieldName, selectedItem.value);
        } else {
            await formValidation.setFieldValue(fieldName, undefined);
        }
    };

    const onDatePickerChange = (selectedDate: Date | undefined) => {
        if (selectedDate) {
            formValidation.setFieldValue("dateTo", selectedDate.toISOString());
        } else {
            formValidation.setFieldValue("dateTo", undefined);
        }
    };

    const onBaseRadioChange = async (selectedRadio: number) => {
        await formValidation.setFieldValue("salesType", selectedRadio);
        if (selectedRadio == CreatePaymentLinkSalesType.SingleSales) {
            await formValidation.setFieldValue("exactSalesCount", 1);
        } else {
            await formValidation.setFieldValue("exactSalesCount", undefined);
        }
    };

    const updateproductsValue = (quantity: number, unitPrice: number, index: number) => {
        var productAmount = new Decimal((quantity ?? 0) * unitPrice).toNumber();
        const values = prodValues;
        values[index].amount = productAmount;
        values[index].amountWithoutDiscount = productAmount;
        setProdValues(values);
        formValidation.setFieldValue("productValues", values);
    };

    const onProductQuantityChange = async (event: any, index: number) => {
        const quantity = event.target.value;
        const values = prodValues;
        values[index].quantity = quantity;
        setProdValues(values);
        formValidation.setFieldValue("productValues", values);
        const unitPrice = formValidation.values["productValues"][index].unitPrice ?? 0;
        updateproductsValue(quantity, unitPrice, index);

        calculateTotalAmount();
    };

    const onUnitPriceChange = async (value: number, index: number) => {
        const values = prodValues;
        values[index].unitPrice = value;
        setProdValues(values);
        formValidation.setFieldValue("productValues", values);
        const quantity = formValidation.values["productValues"][index].quantity ?? 0;
        updateproductsValue(quantity, value, index);

        calculateTotalAmount();
    };

    const onAmountChange = async (value: number, index: number) => {
        const amount = value;
        const values = prodValues;
        values[index].amount = amount;
        setProdValues(values);
        formValidation.setFieldValue("productValues", values);

        calculateTotalAmount();
    };

    const getTodayDate = () => {
        const today = new Date();

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    };

    const calculateTotalAmount = () => {
        const totalAmount = prodValues
            .filter(x => x.amount != undefined)
            .map(x => new Decimal(Number(x.amount!)))
            .reduce((a, b) => a.plus(b), new Decimal(0))
            .toNumber();

        formValidation.setFieldValue("totalAmount", totalAmount);
    };

    const getFirstFormikError = () => {
        for (const key in formValidation.errors) {
            //@ts-ignore
            const error = formValidation.errors[key];

            if (typeof error === "string" && error !== undefined) {
                return error;
            }

            if (Array.isArray(error)) {
                for (const nestedObject of error) {
                    for (const nestedKey in nestedObject) {
                        const nestedError = nestedObject[nestedKey];
                        if (typeof nestedError === "string" && nestedError !== undefined) {
                            return nestedError;
                        }
                    }
                }
            }
        }

        return null; // Return null if no error found
    };

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal" size="lg">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="create-payment-link__modal-body">
                    {isLoading ? (
                        <div className="create-payment-link__modal-loader">
                            <OpacityLoader />
                        </div>
                    ) : (
                        <>
                            <div className="create-payment-link__settings-buyer-info">
                                <div className="create-payment-link__settings">
                                    <div className="create-payment-link__subtitle">{`${t(
                                        StringResources.modal.createPaymentLink.settingsTitle
                                    )}`}</div>
                                    <div className="create-payment-link__settings-input">
                                        <BaseSelect
                                            label={`${t(
                                                StringResources.modal.createPaymentLink.paymentPageOptionsLabel
                                            )}`}
                                            options={paymentPageOptions.map(x => {
                                                return {
                                                    ...x,
                                                    selected: x.value == formValidation.values["paymentPage"],
                                                };
                                            })}
                                            onChange={option => onPaymentPageChange(option)}
                                            isClearable={false}
                                            invalid={
                                                formValidation.errors["paymentPage"] &&
                                                formValidation.touched["paymentPage"]
                                                    ? formValidation.errors["paymentPage"]
                                                    : false
                                            }
                                            onBlur={e => {
                                                formValidation.setTouched({ paymentPage: true });
                                                formValidation.handleBlur(e);
                                            }}
                                            toolTipDefinitions={[
                                                `${t(StringResources.modal.createPaymentLink.paymentPageToolTip)}`,
                                            ]}
                                        ></BaseSelect>
                                    </div>
                                    <div className="create-payment-link__settings-input">
                                        <BaseSelect
                                            label={`${t(
                                                StringResources.modal.createPaymentLink.paymentLinkThemeOptionsLabel
                                            )}`}
                                            options={paymentLinkThemeOptions.map(x => {
                                                return {
                                                    ...x,
                                                    selected: x.value == formValidation.values["paymentLinkTheme"],
                                                };
                                            })}
                                            onChange={option => onOptionValueChange(option, "paymentLinkTheme")}
                                            isClearable={true}
                                            invalid={
                                                formValidation.errors["paymentLinkTheme"] &&
                                                formValidation.touched["paymentLinkTheme"]
                                                    ? formValidation.errors["paymentLinkTheme"]
                                                    : false
                                            }
                                            onBlur={e => {
                                                formValidation.setTouched({ paymentLinkTheme: true });
                                                formValidation.handleBlur(e);
                                            }}
                                            toolTipDefinitions={[
                                                `${t(StringResources.modal.createPaymentLink.paymentPageThemeToolTip)}`,
                                            ]}
                                        ></BaseSelect>
                                    </div>
                                    <div className="create-payment-link__settings-input">
                                        <BaseInput
                                            type={"text"}
                                            name="payByLinkDescription"
                                            value={formValidation.values["payByLinkDescription"]}
                                            label={`${t(StringResources.modal.createPaymentLink.payByLinkDescription)}`}
                                            onBlur={formValidation.handleBlur}
                                            onChange={formValidation.handleChange}
                                            toolTipDefinitions={[
                                                `${t(
                                                    StringResources.modal.createPaymentLink.payByLinkDescriptionToolTip
                                                )}`,
                                            ]}
                                        ></BaseInput>
                                    </div>
                                    <div className="create-payment-link__settings-input">
                                        <BaseInput
                                            type="text"
                                            name="purchaseReturnUrl"
                                            invalid={
                                                formValidation.touched["purchaseReturnUrl"] &&
                                                formValidation.errors["purchaseReturnUrl"]
                                            }
                                            value={formValidation.values["purchaseReturnUrl"]}
                                            label={`${t(StringResources.modal.createPaymentLink.purchaseReturnUrl)}`}
                                            onBlur={formValidation.handleBlur}
                                            onChange={formValidation.handleChange}
                                            toolTipDefinitions={[
                                                `${t(
                                                    StringResources.modal.createPaymentLink.purchaseReturnUrlToolTip
                                                )}`,
                                            ]}
                                        ></BaseInput>
                                    </div>
                                    <div className="create-payment-link__settings-input">
                                        <BaseSelect
                                            label={`${t(StringResources.modal.createPaymentLink.languageOptionsLabel)}`}
                                            options={languages.map(x => {
                                                return {
                                                    label:
                                                        x.name == "Icelandic"
                                                            ? `${t(
                                                                  StringResources.enums.language[
                                                                      SupportedLanguageEnum.Icelandic
                                                                  ]
                                                              )}`
                                                            : `${t(
                                                                  StringResources.enums.language[
                                                                      SupportedLanguageEnum.English
                                                                  ]
                                                              )}`,
                                                    value: x.value,
                                                    selected: x.value == formValidation.values["language"],
                                                };
                                            })}
                                            onChange={option => onOptionValueChange(option, "language")}
                                            onBlur={e => {
                                                formValidation.setTouched({ language: true });
                                                formValidation.handleBlur(e);
                                            }}
                                            invalid={
                                                formValidation.errors["language"] && formValidation.touched["language"]
                                                    ? formValidation.errors["language"]
                                                    : false
                                            }
                                            isClearable={false}
                                            toolTipDefinitions={[
                                                `${t(StringResources.modal.createPaymentLink.defaultLanguageToolTip)}`,
                                            ]}
                                        ></BaseSelect>
                                    </div>
                                    <div className="create-payment-link__settings-input">
                                        <BaseSelect
                                            label={`${t(StringResources.modal.createPaymentLink.hasExpirationDate)}`}
                                            options={expirationDateOptions.map(x => {
                                                return {
                                                    ...x,
                                                    selected: x.value == formValidation.values["hasExpirationDate"],
                                                };
                                            })}
                                            onChange={option => onOptionValueChange(option, "hasExpirationDate")}
                                            isClearable={false}
                                            invalid={
                                                formValidation.errors["hasExpirationDate"] &&
                                                formValidation.touched["hasExpirationDate"]
                                                    ? formValidation.errors["hasExpirationDate"]
                                                    : false
                                            }
                                            onBlur={e => {
                                                formValidation.setTouched({ hasExpirationDate: true });
                                                formValidation.handleBlur(e);
                                            }}
                                        ></BaseSelect>
                                    </div>
                                    {formValidation.values["hasExpirationDate"] === true && (
                                        <div className="create-payment-link__settings-input">
                                            <BaseDatepicker
                                                label={`${t(StringResources.modal.createPaymentLink.validTo)}`}
                                                onDateChange={onDatePickerChange}
                                                isClearable={false}
                                                value={formValidation.values["dateTo"]}
                                                minValue={getTodayDate()}
                                                invalid={
                                                    formValidation.errors["dateTo"]
                                                        ? formValidation.errors["dateTo"]
                                                        : false
                                                }
                                            ></BaseDatepicker>
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <div className="create-payment-link__subtitle">{`${t(
                                        StringResources.modal.createPaymentLink.buyerInfoTitle
                                    )}`}</div>
                                    {formValidation.values.shopperInfoData.map((item, index) => (
                                        <div className="create-payment-link__buyer-info-options">
                                            <div className="create-payment-link__buyer-info-options-label">
                                                {item.label}
                                            </div>

                                            <div className="create-payment-link__buyer-info-options-option">
                                                <BaseToggleSwitch
                                                    options={[
                                                        {
                                                            label: t(
                                                                StringResources.modal.createPaymentLink.hiddenLabel
                                                            ),
                                                            value: ShopperInfoRequirementType.Hidden,
                                                        },
                                                        {
                                                            label: t(
                                                                StringResources.modal.createPaymentLink.optionalabel
                                                            ),
                                                            value: ShopperInfoRequirementType.Optional,
                                                        },
                                                        {
                                                            label: t(
                                                                StringResources.modal.createPaymentLink.requiredLabel
                                                            ),
                                                            value: ShopperInfoRequirementType.Required,
                                                        },
                                                    ]}
                                                    activeValue={item.shopperInfoRequirementType}
                                                    onChange={option =>
                                                        formValidation.setFieldValue(
                                                            `shopperInfoData[${index}].shopperInfoRequirementType`,
                                                            option.value
                                                        )
                                                    }
                                                ></BaseToggleSwitch>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="create-payment-link__subtitle">
                                {`${t(StringResources.modal.createPaymentLink.salesTitle)}`}
                            </div>
                            <div className="create-payment-link__sales">
                                <div>
                                    <BaseRadio
                                        label={`${t(StringResources.modal.createPaymentLink.unlimitedSales)}`}
                                        name={"radio-2"}
                                        checked={
                                            formValidation.values["salesType"] ==
                                            CreatePaymentLinkSalesType.UnlimitedSales
                                        }
                                        value={CreatePaymentLinkSalesType.UnlimitedSales}
                                        className={"create-payment-link__sales--radio-group"}
                                        onChange={() => onBaseRadioChange(CreatePaymentLinkSalesType.UnlimitedSales)}
                                    ></BaseRadio>
                                </div>

                                <div>
                                    <BaseRadio
                                        label={`${t(StringResources.modal.createPaymentLink.exactSales)}`}
                                        name={"radio-3"}
                                        checked={
                                            formValidation.values["salesType"] == CreatePaymentLinkSalesType.ExactSales
                                        }
                                        value={CreatePaymentLinkSalesType.ExactSales}
                                        className={"create-payment-link__sales--radio-group"}
                                        onChange={() => onBaseRadioChange(CreatePaymentLinkSalesType.ExactSales)}
                                    ></BaseRadio>
                                </div>

                                <div>
                                    <BaseRadio
                                        label={`${t(StringResources.modal.createPaymentLink.singleSales)}`}
                                        name={"radio-1"}
                                        checked={
                                            formValidation.values["salesType"] == CreatePaymentLinkSalesType.SingleSales
                                        }
                                        value={CreatePaymentLinkSalesType.SingleSales}
                                        className={"create-payment-link__sales--radio-group"}
                                        onChange={() => onBaseRadioChange(CreatePaymentLinkSalesType.SingleSales)}
                                    ></BaseRadio>
                                </div>

                                {formValidation.values["salesType"] == CreatePaymentLinkSalesType.ExactSales && (
                                    <div className="create-payment-link__sales--item">
                                        <BaseInput
                                            label={`${t(StringResources.modal.createPaymentLink.exactSalesCount)}`}
                                            type={"number"}
                                            name="exactSalesCount"
                                            value={formValidation.values["exactSalesCount"]}
                                            onBlur={formValidation.handleBlur}
                                            onChange={formValidation.handleChange}
                                            disabled={
                                                formValidation.values["salesType"] !==
                                                CreatePaymentLinkSalesType.ExactSales
                                            }
                                        ></BaseInput>
                                    </div>
                                )}
                            </div>
                            <div className="create-payment-link__subtitle">{`${t(
                                StringResources.modal.createPaymentLink.productsTitle
                            )}`}</div>
                            <div className="create-payment-link__products">
                                <div className="create-payment-link__products-header-title">
                                    {`${t(StringResources.modal.createPaymentLink.productsDescription)}`}
                                </div>
                                <div className="create-payment-link__products-header-title">
                                    {`${t(StringResources.modal.createPaymentLink.productsQuantity)}`}
                                </div>
                                <div className="create-payment-link__products-header-title">
                                    {`${t(StringResources.modal.createPaymentLink.productsUnitPrice)}`}
                                </div>
                                <div className="create-payment-link__products-header-title">
                                    {`${t(StringResources.modal.createPaymentLink.productAmountWithoutDiscount)}`}
                                </div>
                                <div className="create-payment-link__products-header-title">
                                    {`${t(StringResources.modal.createPaymentLink.productsAmount)}`}
                                </div>
                                <div></div>
                                {prodValues.map((prodValue, index) => {
                                    const isLast = index === prodValues.length - 1;

                                    return (
                                        <CreatePaymentLinkModalProductRow
                                            key={prodValue.id}
                                            isLast={isLast}
                                            {...prodValue}
                                            onBlur={() => {
                                                formValidation.handleBlur;
                                            }}
                                            onChange={(propName, event) => {
                                                const values = prodValues;

                                                if (propName === "description") {
                                                    values[index].description = event.target.value;
                                                    setProdValues(values);
                                                    formValidation.setFieldValue("productValues", values);
                                                } else if (propName === "quantity") {
                                                    onProductQuantityChange(event, index);
                                                }
                                            }}
                                            onNumberValueChange={(propName, value) => {
                                                if (propName === "unitPrice") {
                                                    onUnitPriceChange(value, index);
                                                } else if (propName === "amount") {
                                                    onAmountChange(value, index);
                                                }
                                            }}
                                            checkInvalid={(
                                                propname: keyof IProductRow,
                                                value: string | number | undefined
                                            ) => {
                                                if (
                                                    formValidation.errors["productValues"] === undefined ||
                                                    formValidation.errors["productValues"]![index] === undefined
                                                ) {
                                                    return false;
                                                }

                                                // @ts-ignore
                                                return formValidation.errors["productValues"]![index][`${propname}`];
                                            }}
                                            onAddRow={
                                                isLast
                                                    ? () => {
                                                          const values = [...prodValues];
                                                          values.push({
                                                              id: createUUID(),
                                                              description: undefined,
                                                              quantity: 0,
                                                              amountWithoutDiscount: 0,
                                                              unitPrice: 0,
                                                              amount: 0,
                                                              currencyId: currencyId,
                                                          });
                                                          setProdValues(values);
                                                          formValidation.setFieldValue("productValues", values);
                                                      }
                                                    : undefined
                                            }
                                            onDeleteRow={
                                                isLast
                                                    ? undefined
                                                    : () => {
                                                          const values = [...prodValues];
                                                          const updatedValues = values.filter((_, i) => i !== index);
                                                          setProdValues(updatedValues);
                                                          formValidation.setFieldValue("productValues", updatedValues);
                                                          return;
                                                      }
                                            }
                                        />
                                    );
                                })}
                            </div>
                            <div className="create-payment-link__total">
                                <>
                                    <div>
                                        {`${t(StringResources.modal.createPaymentLink.totalAmount)}`} :{" "}
                                        {formatter(
                                            prodValues
                                                .filter(x => x.amount != undefined)
                                                .map(x => new Decimal(Number(x.amount!)))
                                                .reduce((a, b) => a.plus(b), new Decimal(0))
                                                .toNumber(),
                                            currencyId
                                        )}
                                    </div>
                                    {formValidation.errors && (
                                        <div className="create-payment-link__total-error">{getFirstFormikError()}</div>
                                    )}
                                </>
                            </div>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.modal.cancel)}`}
                        styleType="line"
                    />

                    <BaseButton
                        handleClick={onOkClick}
                        text={props.actionButtonText}
                        styleType="solid"
                        disabled={!(formValidation.isValid && !formValidation.isValidating) && !isLoading}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CreatePamentLinkModal;
